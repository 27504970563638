import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Link, Stack, CircularProgress, Chip } from '@mui/material'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import HeadphonesIcon from '@mui/icons-material/Headphones'
import ImageIcon from '@mui/icons-material/Image'
import NotesIcon from '@mui/icons-material/Notes'

import { Section } from '../../../layouts/MainLayout/styles'

const Lesson = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'image', 'audio']).isRequired,
  status: PropTypes.string.isRequired,
  duration: PropTypes.number,
  words: PropTypes.number,
  highlights: PropTypes.number,
})

const LessonIcon = ({ data: { type, status } }) => {
  if (status === 'pending') return <CircularProgress size={20} />
  switch (type) {
    case 'image':
      return <ImageIcon />

    case 'audio':
      return <HeadphonesIcon />

    default:
      return <ArticleOutlinedIcon />
  }
}

LessonIcon.propTypes = {
  data: Lesson.isRequired,
}

const Duration = ({ duration }) => {
  if (!duration) return null
  const minutes = _.round(duration / 60)
  const label = minutes ? `${minutes} min` : `${duration} sec`
  return <Chip label={label} size="small" icon={<HeadphonesIcon />} />
}
Duration.propTypes = { duration: PropTypes.number }
Duration.defaultProps = { duration: null }

const Words = ({ words }) => {
  if (!words) return null
  return <Chip label={`${words} words`} size="small" icon={<NotesIcon />} />
}
Words.propTypes = { words: PropTypes.number }
Words.defaultProps = { words: null }

const Highlights = ({ highlights }) => {
  if (!highlights) return null
  return (
    <Chip label={highlights} size="small" icon={<BorderColorOutlinedIcon />} />
  )
}
Highlights.propTypes = { highlights: PropTypes.number }
Highlights.defaultProps = { highlights: null }

const LessonInfo = ({ data, sx }) => {
  return (
    <Section key={data.id} {...{ sx }}>
      <aside>
        <LessonIcon data={data} />
      </aside>
      <Link href={`/lesson/${data.id}`} sx={{ textDecoration: 'none' }}>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          {data.name}
          <Stack direction="row" spacing={1}>
            {data.duration ? (
              <Duration duration={data.duration} />
            ) : (
              <Words words={data.words} />
            )}
            {/* <Highlights highlights={data.highlights} /> */}
          </Stack>
        </Stack>
      </Link>
    </Section>
  )
}

LessonInfo.propTypes = {
  data: Lesson.isRequired,
  sx: PropTypes.shape({}),
}

LessonInfo.defaultProps = {
  sx: null,
}

export default LessonInfo
