import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  CardHeader,
  CardContent,
  LinearProgress,
  Stack,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import bn from 'bignumber.js'

import { useProfileContext } from '../../../contexts/profile'
import { getProducts } from '../../../configs/stripe'
import { createCheckoutSession } from '../../../configs/api'
import { estimateAudioHours } from '../../../utils/tokenomics'
import { CardCallout, Emphasys } from '../styles'
import PurchaseItemInfo from './PurchaseItemInfo'
import { errorCapture } from '../../../utils/errorCapture'

const normalizePrice = (value) =>
  Number(bn(value).div(100).toFixed(2, bn.ROUND_UP))

const Plan = ({
  interval,
  price,
  tokens,
  onSelect,
  disabled,
  showProgress,
}) => {
  const isAnnual = interval === 'year'
  const name = isAnnual ? 'Annual' : 'Monthly'
  return (
    <Card sx={{ height: '100%' }} elevation={isAnnual ? 5 : 1}>
      <CardActionArea
        onClick={onSelect}
        disabled={disabled}
        sx={{ height: '100%' }}
      >
        <Stack direction="column" sx={{ height: '100%' }}>
          <CardHeader title={name} titleTypographyProps={{ variant: 'h6' }} />
          <CardContent sx={{ flexGrow: 10 }}>
            <Typography component="p" mb={4}>
              {isAnnual ? (
                // TODO calculate the amount of free months
                <>
                  Get <Emphasys>two months for free</Emphasys> as a commited
                  language learner.
                </>
              ) : (
                <>
                  Transcribe and translate{' '}
                  <Emphasys>up to {estimateAudioHours(tokens)} hours</Emphasys>{' '}
                  of audio content monthly.
                </>
              )}
            </Typography>
          </CardContent>
          {isAnnual && (
            <CardContent>
              <PurchaseItemInfo
                name="yearly"
                value={<b>{normalizePrice(price)}€</b>}
                color="text.secondary"
              />
            </CardContent>
          )}
          {showProgress && <LinearProgress />}
          <CardCallout>
            <PurchaseItemInfo
              name="per month"
              value={<b>{normalizePrice(bn(price).div(isAnnual ? 12 : 1))}€</b>}
              highlight
            />
          </CardCallout>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

Plan.propTypes = {
  interval: PropTypes.string.isRequired,
  tokens: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  showProgress: PropTypes.bool,
}

Plan.defaultProps = {
  disabled: false,
  showProgress: false,
  tokens: null,
}

const Plans = () => {
  const { isLoading } = useProfileContext()
  const [isPending, setPending] = useState(false)
  const [products, setProducts] = useState([])
  const [priceId, setPriceId] = useState()

  useEffect(() => {
    getProducts()
      .then((res) => {
        if (!res.length) return
        setProducts(res)
      })
      .catch(errorCapture)
  }, [])

  const purchase = useCallback(
    async (id) => {
      if (isPending) return
      setPending(true)
      setPriceId(id)
      const session = await createCheckoutSession({
        priceId: id,
        returnUrl: window.location.href,
      })
      window.location.assign(session.data.url)
    },
    [isPending]
  )

  return (
    <Box component="section" mt={2}>
      <Typography variant="h5">Subscription plans</Typography>
      {/* {!isLoading && !firstPurchaseAt && (
        <Alert sx={{ mt: 2 }}>
          <Typography>
            First-time purchase discount will be automatically applied.
          </Typography>
        </Alert>
      )} */}
      {!isLoading && products.length > 0 && (
        <Grid container spacing={4} mt={2} sx={{ alignItems: 'stretch' }}>
          <Grid tablet={4}>
            <p>
              Get access to Saanaru services to make audio transcripts and
              translations for your language learning.
            </p>
            <p>
              You will be granted with a monthly quota of{' '}
              <Emphasys>{products[0].tokens.toLocaleString()} tokens</Emphasys>{' '}
              to use on those services.
            </p>
          </Grid>
          {products.map((p) => (
            <Grid key={p.id} tablet={4}>
              <Plan
                interval={p.interval}
                description={p.description}
                price={p.price}
                tokens={p.tokens}
                onSelect={() => purchase(p.id)}
                disabled={isPending}
                showProgress={isPending && priceId === p.id}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  )
}

export default Plans
