import { useParams } from 'react-router-dom'
import _ from 'lodash'

import { useProfileContext } from '../contexts/profile'

export default function useLessonParams() {
  const { id } = useParams()
  const { uid } = useProfileContext()
  const [lessonId, userId] = _.take(_.split(id, '-'), 2)
  return { id: lessonId === 'new' ? null : lessonId, uid: userId || uid }
}
