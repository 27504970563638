import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import pinyin from 'pinyin'

import { isChinese, isJapanese } from '../../../../utils/languages'
import * as types from '../../../../types'

const zhAnnotate = _.memoize((t) => pinyin(t, { group: true }))
const nbsp = <>&nbsp;</>

const annotate = ({ lang, token }) => {
  let { rt } = token
  if (!rt) {
    if (isChinese(lang)) {
      rt = zhAnnotate(token.text)
    } else if (isJapanese(lang)) {
      const { hir, rom } = token
      if (hir || rom)
        rt = (
          <>
            <span className="romaji">{rom || nbsp}</span>
            <br />
            <span className="furigana">{hir || nbsp}</span>
          </>
        )
    }
  }
  return rt && <rt>{rt}</rt>
}

const Token = ({
  token,
  lang,
  highlighted,
  onPointerDown,
  onPointerMove,
  onPointerUp,
}) => {
  const tag = token.tag && token.tag.toLowerCase()
  const { text, id } = token
  const [hovered, setHovered] = useState(false)
  const onHighlightEnd = useCallback(
    (e) => {
      onPointerUp(e)
      setHovered(false)
    },
    [onPointerUp]
  )

  const classes =
    [
      tag,
      (token.hl || highlighted || hovered) && 'hl',
      token.sb === '' && 'nosb',
    ]
      .filter(Boolean)
      .join(' ') || null

  return (
    <React.Fragment key={id}>
      <ruby
        data-id={id}
        className={classes}
        onPointerDown={onPointerDown || null}
        onPointerMove={onPointerMove || null}
        onPointerUp={onPointerUp ? onHighlightEnd : null}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onTouchStart={() => setHovered(true)}
        onTouchEnd={() => setHovered(false)}
      >
        {text}
        {annotate({ lang, token })}
      </ruby>
    </React.Fragment>
  )
}

Token.propTypes = {
  token: types.Token.isRequired,
  lang: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
  onPointerDown: PropTypes.func,
  onPointerMove: PropTypes.func,
  onPointerUp: PropTypes.func,
}

Token.defaultProps = {
  highlighted: false,
  onPointerDown: null,
  onPointerMove: null,
  onPointerUp: null,
}

export default Token
