import _ from 'lodash'

const googleTranslate = require('./googleTranslate.json')
const googleVision = require('./googleVision.json')
const speechToText = require('./speechToText.json')

// Maximum languages we can support is base on translation capability
// NOTE codes are 2-3 letter lower-case language codes
export const text = _.sortBy(googleTranslate, 'name')
export const image = _.sortBy(googleVision, 'name')

// Normalize language to ISO 639-1
export const normalizeLang = (l) =>
  l ? l.split('-')[0].toLocaleLowerCase() : l

const languageNameOnly = (name) => name.replace(/\s+\(.+\)/, '').split(',')[0]

// Keep region name in a language name obly if there are few languages for the region
export const cleanupNames = (languages) =>
  _(languages)
    .map((l) => ({ ...l, lang: normalizeLang(l.code) }))
    .groupBy('lang')
    .map((gr) =>
      _.size(gr) > 1
        ? gr
        : [
            {
              ...gr[0],
              name: languageNameOnly(gr[0].name),
            },
          ]
    )
    .values()
    .flatten()
    .map((l) => _.omit(l, 'lang'))

export const sortByName = (languages) =>
  _(languages)
    .map((l) => ({ ...l, normName: languageNameOnly(l.name) }))
    .sortBy('normName', 'sort', 'name')
    .map((l) => _.omit(l, ['normName', 'sort']))
    .value()

// Normaline language codes and name for Speech service
// export const audio = sortByName(cleanupNames(azureSpeech).values())
export const audio = sortByName(speechToText)

export const select = _.memoize(
  (language, list) => {
    if (_.isEmpty(language)) return ''

    const codes = _(list).map((l) => l.code)

    // Try checking as it is
    if (codes.includes(language)) return language

    // Normalize and try again
    let [lang, region] = language.split('-')
    lang = _.toLower(lang)
    region = _.toUpper(region)
    const normalized = _([lang, region]).reject(_.isEmpty).join('-')
    if (codes.includes(normalized)) return normalized

    // Try just language part
    if (codes.includes(lang)) return lang

    // Try trimming region information
    const re = new RegExp(`^${lang}-`)
    const code = _(codes)
      .filter((l) => l.match(re))
      .first()

    return code || ''
  },
  (language, list) => `${language}@${JSON.stringify(list)}`
)

export const isChinese = (lang) => normalizeLang(select(lang, text)) === 'zh'
export const isJapanese = (lang) => normalizeLang(select(lang, text)) === 'ja'
