import React from 'react'
import PropTypes from 'prop-types'
import { Alert, AlertTitle } from '@mui/material'

import { useProfileContext } from '../../contexts/profile'
import BillingPageButton from './BillingPageButton'

const TranslationQuotaAlert = ({ characters }) => {
  const { isTrial } = useProfileContext()

  return (
    <Alert severity="error" action={<BillingPageButton />}>
      <AlertTitle>Not enough quota to translate the lesson</AlertTitle>
      {isTrial
        ? 'You exhausted your trial quota. Please consider subscribing.'
        : 'Check your subscription status on the billing page'}
    </Alert>
  )
}

TranslationQuotaAlert.propTypes = {
  characters: PropTypes.number,
}

TranslationQuotaAlert.defaultProps = {
  characters: null,
}

export default TranslationQuotaAlert
