import React, { useState, useReducer, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import getUserLocale from 'get-user-locale'
import { FormControl, Stack, Typography, Container } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import { updateDoc } from 'firebase/firestore'

import { useProfileContext } from '../../contexts/profile'
import { LanguageSelect, QuotasButton } from '../../components'
import { profileRef } from '../../configs/firestore'
import { errorCapture } from '../../utils/errorCapture'
import { normalizeLang } from '../../utils/languages'
import { useAffiliate } from '../../hooks/useAffiliate'
import MainLayout, { BackButton } from '../../layouts/MainLayout'
import SignoutButton from './components/SignoutButton'

function profileDataReducer(state, { field, value }) {
  switch (field) {
    case 'nativeLanguage':
    case 'targetLanguage':
      return { ...state, [field]: normalizeLang(value) }
    default:
      return state
  }
}

const Profile = () => {
  const navigate = useNavigate()
  const { uid, nativeLanguage, targetLanguage, isOnboarded, ref, createdAt } =
    useProfileContext()
  const { refKey, refCode } = useAffiliate()

  const [data, dispatch] = useReducer(profileDataReducer, {
    nativeLanguage: nativeLanguage || normalizeLang(getUserLocale()),
    targetLanguage: targetLanguage || '',
  })

  const [isPending, togglePending] = useState(false)
  const canSave =
    data.nativeLanguage &&
    data.targetLanguage &&
    (data.nativeLanguage !== nativeLanguage ||
      normalizeLang(data.targetLanguage) !== normalizeLang(targetLanguage))

  // Save affiliate refferal link during the onboarding
  useEffect(() => {
    if (ref || !createdAt || !uid) return
    const patch = {
      ref:
        // save either referral code
        refCode ||
        // or prevent overriding referrals for users who already signed up
        'none',
    }
    updateDoc(profileRef(uid), patch)
      .then(() => {
        window.localStorage.removeItem(refKey)
      })
      .catch((e) => {
        e.message = `${e.message} Update profile ref code "${patch.ref}"`
        errorCapture(e)
      })
  }, [ref, refKey, refCode, uid, createdAt])

  const onSubmit = async (event) => {
    if (isPending || !canSave) return
    event.preventDefault()
    if (isPending) return
    togglePending(true)
    try {
      await updateDoc(profileRef(uid), data)
      navigate('/')
    } catch (error) {
      errorCapture(error)
      togglePending(false)
    }
  }

  return (
    <MainLayout
      isLoading={isPending}
      title="My profile"
      actions={
        <>
          {isOnboarded && <QuotasButton />}
          <SignoutButton />
        </>
      }
      noBackButton={!isOnboarded}
      backButton={<BackButton to="/" icon={<CloseIcon />} />}
      noWrap
    >
      <Container container="main" maxWidth="mobile">
        <Stack component="form" spacing={2} onSubmit={onSubmit}>
          <Typography variant="h6">I learn...</Typography>
          <FormControl>
            <LanguageSelect
              value={data.targetLanguage}
              onChange={(value) => dispatch({ field: 'targetLanguage', value })}
              disabled={isPending}
              labelText="Target Language"
            />
          </FormControl>
          <Typography variant="h6">I natively speak...</Typography>
          <FormControl>
            <LanguageSelect
              value={data.nativeLanguage}
              onChange={(value) => dispatch({ field: 'nativeLanguage', value })}
              disabled={isPending}
              labelText="Native Language"
            />
          </FormControl>
          <LoadingButton
            variant="contained"
            loading={isPending}
            disabled={!canSave}
            type="submit"
          >
            Save
          </LoadingButton>
        </Stack>
      </Container>
    </MainLayout>
  )
}

export default Profile
