import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FileSaver from 'file-saver'
import { getAuth } from 'firebase/auth'
import { getToken } from 'firebase/app-check'
import {
  ListItemIcon,
  MenuItem,
  Typography,
  CircularProgress,
} from '@mui/material'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import axios from 'axios'

import { errorCapture } from '../../../../utils/errorCapture'
import { appCheck } from '../../../../configs/firebase'
import { sendEvent } from '../../../../utils/analytics'

const download = async (name) => {
  const url = `${process.env.REACT_APP_API}/download-lessonPdfV2`
  try {
    const authToken = await getAuth().currentUser.getIdToken()
    const { token: appCheckToken } = await getToken(appCheck, false)

    // Get CSS and insert it to the HTML as a workaround for fetching remote resources
    const css = Array.from(document.styleSheets)
      .map((styleSheet) =>
        Array.from(styleSheet.cssRules)
          .map((rule) => rule.cssText)
          .join('')
      )
      .join('')
    const htmlWithoutStyle = document.documentElement.outerHTML.replace(
      /<style[^>]*>.*<\/style>/gs,
      ''
    )
    const newStyleTag = `<style>${css}</style>`
    const html = htmlWithoutStyle.replace(/<\/head>/, `${newStyleTag}</head>`)

    const res = await axios.post(
      url,
      { content: html },
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'X-Firebase-AppCheck': appCheckToken,
        },
      }
    )
    FileSaver.saveAs(res.data, `${name}.pdf`, {
      type: res.headers['content-type'],
    })
  } catch (error) {
    errorCapture(error)
  }
}

const DownloadPdfButton = ({ name, type }) => {
  const [isPending, togglePending] = useState(false)

  const onClick = async () => {
    if (isPending) return
    togglePending(true)
    sendEvent('lesson.downloadPdf', { type })
    await download(name)
    togglePending(false)
  }

  return (
    <MenuItem onClick={onClick} disabled={isPending}>
      <ListItemIcon>
        {isPending ? (
          <CircularProgress size="1.25rem" />
        ) : (
          <PictureAsPdfIcon color="primary" />
        )}
      </ListItemIcon>
      <Typography color="primary" variant="overline">
        Download
      </Typography>
    </MenuItem>
  )
}

DownloadPdfButton.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
}

DownloadPdfButton.defaultProps = {
  name: null,
}

export default DownloadPdfButton
