import { styled } from '@mui/system'
import { grey } from '@mui/material/colors'
import { CardContent } from '@mui/material'

export const CardCallout = styled(CardContent)(({ theme }) => ({
  color: theme.palette.text.primary,

  '&': {
    backgroundColor: grey[200],
  },
}))

export const Emphasys = styled('span')(({ theme }) => ({
  whiteSpace: 'nowrap',
  fontWeight: theme.typography.fontWeightBold,
}))
