import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  ListItemIcon,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import RestartAltIcon from '@mui/icons-material/RestartAlt'

import { Lesson } from '../../../../types'
import { errorCapture } from '../../../../utils/errorCapture'
import { parseAudioLesson } from '../../../../configs/api'

const ParseAgain = ({ lesson, onClose }) => {
  const [isPending, togglePending] = useState(false)

  const [open, setOpen] = useState(false)
  const handleClickOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    onClose && onClose()
  }

  const onConfirm = () => {
    if (isPending) return
    try {
      togglePending(true)
      parseAudioLesson({ id: lesson.id })
    } catch (error) {
      errorCapture(error)
      togglePending(false)
    }
    handleClose()
  }

  if (
    lesson.status === 'pending' ||
    lesson.type !== 'audio' ||
    lesson.isPlayable
  )
    return null

  return (
    <>
      <MenuItem onClick={handleClickOpen} disabled={isPending}>
        <ListItemIcon>
          <RestartAltIcon color="error" fontSize="small" />
        </ListItemIcon>
        <Typography variant="overline" color="error">
          Parse again
        </Typography>
      </MenuItem>
      <Dialog open={open} onClose={handleClose} maxWidth="mobile">
        <DialogTitle>Redo the transcription?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            I will delete the current transcription and translation start over.
            This operation consumes your tokens quota.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Keep it
          </Button>
          <Button color="error" onClick={onConfirm} autoFocus>
            Parse again
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

ParseAgain.propTypes = {
  lesson: Lesson.isRequired,
  onClose: PropTypes.func,
}

ParseAgain.defaultProps = {
  onClose: null,
}

export default ParseAgain
