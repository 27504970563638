// https://codesandbox.io/s/react-auth-with-hooks-and-firebase-rnpqs?file=/src/store/Session.js

import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

export default function useAuth() {
  const [{ uid, isLoggedIn }, setUser] = useState({
    uid: null,
    isLoggedIn: null,
  })

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        Sentry.setUser({ id: user.uid })
        setUser({ uid: user.uid, isLoggedIn: true })
      } else {
        Sentry.configureScope((scope) => scope.setUser(null))
        setUser({ uid: null, isLoggedIn: false })
      }
    })

    // Cleanup subscription on unmount
    return () => unsubscribe()
  }, [])

  return {
    uid,
    isLoggedIn,
  }
}
