import { styled } from '@mui/system'
import { Typography, Box } from '@mui/material'

export const HeaderStack = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  // Without setting up z-index content text will be visible through the header.
  // 100 is above the content but below control elements
  // https://mui.com/material-ui/customization/z-index/
  zIndex: 100,
  borderBottom: '1px solid lightgrey',
  padding: theme.spacing(1, 0),

  '@media print': {
    display: 'none',
  },

  'a, button': {
    lineHeight: 'inherit',
    whiteSpace: 'nowrap',
    marginLeft: 0,
  },
}))

export const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: '2em',
  letterSpacing: 0,
  fontSize: '1.2em',
}))
