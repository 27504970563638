import { getFirestore, collection, doc } from 'firebase/firestore'

export const profileRef = (uid) => doc(getFirestore(), 'profiles', uid)
export const profileSubCollection = (uid, name) =>
  collection(profileRef(uid), name)

export const uploadsCollection = (uid) => profileSubCollection(uid, 'uploads')
export const uploadRef = ({ uid, id }) => doc(uploadsCollection(uid), id)

export const lessonsCollection = (uid) => profileSubCollection(uid, 'lessons')
export const lessonRef = ({ uid, id }) => doc(lessonsCollection(uid), id)
export const contentRef = ({ id }) =>
  doc(collection(getFirestore(), 'content'), id)

export const highlightsCollection = (uid) =>
  profileSubCollection(uid, 'highlights')
export const highlightsRef = ({ uid, id }) => doc(highlightsCollection(uid), id)

export const feed = (uid) => collection(profileRef(uid), 'feed')

export const checkoutSessionRef = (id) =>
  doc(getFirestore(), 'checkoutSessions', id)
