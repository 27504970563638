import heic2any from 'heic2any'
import * as _ from 'lodash'
import { isIOS } from '../../../../configs/browser'

const convertFiles = (files) => {
  return Promise.all(
    _.map(files, (file) => {
      if (!file.type.match(/heic/i) || isIOS) return file
      return new Promise((resolve, reject) => {
        heic2any({ blob: file, toType: 'image/jpeg', quality: 0.5 })
          .then((conversionResult) =>
            resolve(
              new File(
                [conversionResult],
                file.name.replace(/\.[a-z]$/i, '.jpeg'),
                { type: 'image/jpeg' }
              )
            )
          )
          .catch(reject)
      })
    })
  )
}

export default convertFiles
