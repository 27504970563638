import React from 'react'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import ActionButton from '../../../components/ActionButton'

const HighlightsButton = () => (
  <ActionButton href="/highlights" icon={<BorderColorOutlinedIcon />}>
    Highlights
  </ActionButton>
)

export default HighlightsButton
