import _ from 'lodash'
import { useLocation } from 'react-router-dom'

export function useAffiliate() {
  const refKey = 'ref'
  let url = window.location.href
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  // get referral code either from URL or local storage
  const refCode = _.toLower(
    _.trim(params.get(refKey)) || _.trim(window.localStorage.getItem(refKey))
  )

  // save it in the local storage for future references
  if (refCode) {
    window.localStorage.setItem(refKey, refCode)
    // Add ref code to the current url
    params.set(refKey, refCode)
    let paramsString = params.toString()
    paramsString = paramsString ? `?${paramsString}` : ''
    url = `${window.location.origin}${location.pathname}${paramsString}`
  }

  return { refKey, refCode, url }
}
