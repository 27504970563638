import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import SwitchViewMode from './SwitchViewMode'
import { ToolbarTooltip } from '../styles'
import { isChinese } from '../../../utils/languages'
import PlayButton from './Player/PlayButton'
import HelpButton from './HelpButton'
import SpeedButton from './Player/SpeedButton'
import TranslationSwitch from './TranslationSwitch'

const LessonToolbar = ({ lesson, onChange }) => {
  const { lang, type } = lesson
  const hasSyntaxParsed = _.size(_.get(lesson, 'sentences.0.tokens.0.tag')) > 0
  const isStructured = !_.isEmpty(lesson.sentences)

  // TODO read from profile
  const defaultOptions = {
    translation: true,
    syntax: false,
    annotation: false,
    annotationModes: {},
  }
  if (isChinese(lang)) {
    defaultOptions.annotation = true
    defaultOptions.annotationModes.pinyin = true
  }

  const [options, dispatch] = useReducer(
    (state, { name, value }) => {
      if (name.startsWith('annotation')) {
        const mode = name.split('.', 2)[1]
        const newState = {
          ...state,
          annotationModes: { ...state.annotationModes, [mode]: value },
        }
        newState.annotation =
          _.size(_.omitBy(newState.annotationModes, (v) => !v)) > 0
        return newState
      }
      return { ...state, [name]: value }
    },

    defaultOptions
  )

  useEffect(() => {
    onChange(options)
  }, [options, onChange])

  return (
    <>
      <HelpButton />
      {isChinese(lang) && (
        <SwitchViewMode
          label="Pinyin"
          checked={options.annotation}
          loading={!isStructured}
          onToggle={(value) => dispatch({ name: 'annotation.pinyin', value })}
        />
      )}
      {hasSyntaxParsed ||
        (false && (
          <SwitchViewMode
            label="Syntax"
            checked={options.syntax}
            loading={!isStructured}
            onToggle={(value) => dispatch({ name: 'syntax', value })}
            tooltip={
              <ToolbarTooltip>
                Highlight <span className="noun">nouns</span>,{' '}
                <span className="pron">pronouns</span> and{' '}
                <span className="verb">verbs</span>
              </ToolbarTooltip>
            }
          />
        ))}
      <TranslationSwitch
        status={lesson.translationStatus}
        checked={options.translation}
        onToggle={(value) => dispatch({ name: 'translation', value })}
      />
      {type === 'audio' && (
        <>
          <SpeedButton />
          <PlayButton />
        </>
      )}
    </>
  )
}

LessonToolbar.propTypes = {
  lesson: PropTypes.shape({
    type: PropTypes.string,
    wordCount: PropTypes.number,
    lang: PropTypes.string,
    translations: PropTypes.shape({}),
    content: PropTypes.string,
    sentences: PropTypes.arrayOf(PropTypes.shape({})),
    translationStatus: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default LessonToolbar
