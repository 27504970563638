import { styled } from '@mui/system'
import { red, grey, yellow } from '@mui/material/colors'
import { Typography } from '@mui/material'

const syntaxDecoration = {
  '.noun, .pron': {
    borderBottom: '1px solid black',
    borderColor: grey[400],
  },

  '.verb': {
    borderBottom: '3px double black',
    borderColor: grey[400],
  },
}

export const LessonWrapper = styled('article')(({ theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    overflow: 'auto',
  },

  // Vertical dashed line connecting timestamps.
  // NOTE render it only when it's nested to .lesson,
  // otherwise it my appear on highlights page
  '&.lesson section:after': {
    content: '""',
    position: 'absolute',
    left: '2rem',
    height: 'calc(100% - 2px)',
    borderLeft: '1px dashed #757575',
    transform: 'translateX(-50%)',
    zIndex: -5,

    '@media print': {
      display: 'none',
    },
  },

  section: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    breakBefore: 'auto',
    breakAfter: 'auto',
    breakInside: 'avoid',
    float: 'none',

    // Content
    p: {
      margin: 0,

      // Translations
      i: {
        // Hide translation by default
        display: 'none',
        color: theme.palette.text.disabled,
      },
    },

    aside: {
      button: {
        display: 'none',
        backgroundColor: theme.palette.background.default,
        fontSize: 0,
      },
      minHeight: '3rem',

      '@media print': {
        minHeight: 'auto',
      },

      // Transcript timestamps
      time: {
        // Hide timestamps by default
        display: 'none',
        color: theme.palette.text.disabled,
        cursor: 'pointer',
        writingMode: 'vertical-lr',
        fontSize: '0.85rem',

        '&:hover': {
          color: theme.palette.text.primary,
        },

        '@media print': {
          writingMode: 'horizontal-tb',
        },

        // Separete timestamp from vertical dashed line
        span: {
          backgroundColor: theme.palette.background.default,
          padding: theme.spacing(0.5, 0),
        },
      },
    },

    // Show show play button on hover over a timestamp
    'aside:hover, &.current aside': {
      time: {
        display: 'none',
      },
      '.play-button': {
        display: 'block',
      },
    },

    // Show show play button on hover over a timestamp
    '&:hover': {
      '.copy-button': {
        display: 'block',
      },
    },

    ruby: {
      ':not(:first-child):not(.nosb)::before': {
        content: '"\\00a0"',
        display: 'inline-block',
      },

      // Highlights
      '&.hl': {
        backgroundColor: yellow[100],

        '@media print': {
          fontWeight: 'bold',
        },
      },
    },

    // Pinyin and furigana annotations
    rt: {
      // Hide by default
      display: 'none',

      '.furigana': {
        display: 'none',
      },

      '.romaji': {
        display: 'none',
      },
    },

    // Currently playing sentence
    '&.current': {
      backgroundColor: theme.palette.background.contrast,
      button: {
        backgroundColor: theme.palette.background.contrast,
      },
    },
  },

  '&.showTimestamps time': {
    display: 'block',
  },

  '&.showTranslation i': {
    display: 'block',
  },

  // Parts of speech highlight
  '&.showSyntax section': syntaxDecoration,

  // Annotations above the words
  '&.showAnnotation ruby': {
    lineHeight: 2.5,

    rt: {
      display: 'block',
      padding: theme.spacing(0.5),
      fontSize: '80%',
      color: red[900],
    },
  },

  '&.showAnnotation.furigana rt .furigana': {
    display: 'inline',
  },

  '&.showAnnotation.romaji rt .romaji': {
    display: 'inline',
  },
}))

export const LessonInfo = styled('div')(({ theme }) => ({
  display: 'none',

  '@media print': {
    display: 'block',
    color: grey[200],
  },
}))

export const ToolbarTooltip = styled(Typography)({ ...syntaxDecoration })

export const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
})
