import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Stack } from '@mui/material'
import Div100vh from 'react-div-100vh'

import { useProfileContext } from '../../contexts/profile'
import Loader from '../components/Loader'
import { PageWrapper, Header } from './styles'

const AuthLayout = ({ children, title }) => {
  const { isLoggedIn, isLoading } = useProfileContext()
  const isPageLoading = isLoggedIn === null || (isLoggedIn && isLoading)
  if (isPageLoading) return <Loader />

  return (
    <Div100vh>
      <PageWrapper>
        <Header component="header">
          <Typography variant="h2">Saanaru</Typography>
        </Header>
        <Stack component="main" spacing={2} justifyContent="center">
          <Typography variant="h5">{title}</Typography>
          {children}
        </Stack>
      </PageWrapper>
    </Div100vh>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default AuthLayout
