import React from 'react'
import PropTypes from 'prop-types'

import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'

const LessonFromText = ({ onSelect }) => (
  <Card>
    <CardActionArea onClick={() => onSelect('text', [])}>
      <CardHeader
        avatar={<ArticleOutlinedIcon />}
        title="Add a text"
        titleTypographyProps={{ variant: 'h6' }}
        sx={{ pb: 0 }}
      />
      <CardContent>
        <Typography color="text.secondary">
          for interlinear translation and syntax analysis
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
)

LessonFromText.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default LessonFromText
