import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import { useProfileContext } from '../../../contexts/profile'
import Loader from '../../components/Loader'
import { Wrapper } from '../styles'

const PageWrapper = ({ children, isLoading, title, isPublic }) => {
  const profile = useProfileContext()
  const isWaitingProfile = !isPublic && profile.isLoading
  const isPageLoading = isWaitingProfile || isLoading
  return (
    <>
      {isPageLoading && <Loader />}
      <Wrapper>
        {!isWaitingProfile && (
          <>
            <Helmet>
              <title>{title}</title>
            </Helmet>
            {children}
          </>
        )}
      </Wrapper>
    </>
  )
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  isPublic: PropTypes.bool,
}

PageWrapper.defaultProps = {
  isLoading: false,
  title: 'Saanaru',
  isPublic: false,
}

export default PageWrapper
