import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Card, Typography, CardContent, CardActions } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import SettingsIcon from '@mui/icons-material/Settings'
import bn from 'bignumber.js'

import { useProfileContext } from '../../../contexts/profile'
import { getPortalUrl } from '../../../configs/api'

const SubscriptionCard = ({ children, cta }) => {
  const [isPending, setPending] = useState(false)

  const manageSubscription = useCallback(async () => {
    if (isPending) return
    setPending(true)
    const { data } = await getPortalUrl({
      returnUrl: window.location.href,
      locale: 'auto', // Optional, defaults to "auto"
      // configuration: 'bpc_1JSEAKHYgolSBA358VNoc2Hs', // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
    })
    window.location.assign(data.url)
  }, [isPending])

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h6">Subscription</Typography>
        {children}
      </CardContent>
      <CardActions sx={{ justifyContent: 'right' }}>
        <LoadingButton
          loading={isPending}
          loadingPosition="start"
          startIcon={<SettingsIcon />}
          onClick={manageSubscription}
        >
          {cta}
        </LoadingButton>
      </CardActions>
    </Card>
  )
}

SubscriptionCard.propTypes = {
  children: PropTypes.node.isRequired,
  cta: PropTypes.string,
}

SubscriptionCard.defaultProps = {
  cta: 'Manage',
}

const Subscription = () => {
  const { subscription, quota } = useProfileContext()

  if (!subscription) return null

  const subscriptionTokens = bn(subscription.tokens || 0)
  const used = bn
    .max(subscriptionTokens.minus(quota?.tokens || 0), 0)
    .div(subscriptionTokens)
    .times(100)
    .integerValue(bn.ROUND_UP)
    .toNumber()

  switch (subscription.status) {
    case 'canceled':
      return null
    default:
      return (
        <SubscriptionCard cta={subscription.cancelAt ? 'Resume' : 'Manage'}>
          <p>
            You&apos;re on the{' '}
            <b>
              {subscription.name} plan
              {subscription.interval === 'year' ? ' (annual)' : null}
            </b>
            .
          </p>
          <ul>
            {subscription.cancelAt ? (
              <li>
                Subscription will be cancelled on{' '}
                {subscription.cancelAt.toDate().toLocaleDateString()}
              </li>
            ) : (
              <li>
                Subsctiption is <b>{subscription.status}</b>;
              </li>
            )}
            {subscriptionTokens.gt(0) && (
              <li>
                <b>{subscriptionTokens.toNumber().toLocaleString()} tokens</b>{' '}
                monthly quota;
              </li>
            )}
            {used > 0 && (
              <li>
                <b>{used}%</b> of it has been used.
              </li>
            )}
          </ul>
        </SubscriptionCard>
      )
  }
}

export default Subscription
