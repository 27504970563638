import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { updateDoc } from 'firebase/firestore'

import {
  ListItemIcon,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import { lessonRef } from '../../../../configs/firestore'
import { errorCapture } from '../../../../utils/errorCapture'
import { useProfileContext } from '../../../../contexts/profile'

const EditName = ({ lessonId: id, currentName, onClose }) => {
  const { uid } = useProfileContext()
  const [isPending, togglePending] = useState(false)
  const [name, setName] = useState(currentName)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('tablet'))

  const [open, setOpen] = useState(false)
  const handleClickOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  const updateLesson = async () => {
    if (isPending || !name || name === currentName) return
    try {
      togglePending(true)
      await updateDoc(lessonRef({ uid, id }), { name })
    } catch (error) {
      errorCapture(error)
      togglePending(false)
    }
    handleClose()
  }

  return (
    <>
      <MenuItem onClick={handleClickOpen} disabled={isPending}>
        <ListItemIcon>
          <EditIcon color="primary" fontSize="small" />
        </ListItemIcon>
        <Typography color="primary" variant="overline">
          Rename
        </Typography>
      </MenuItem>
      <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
        <DialogTitle>Rename the lesson</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Lesson name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={currentName}
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateLesson} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

EditName.propTypes = {
  lessonId: PropTypes.string.isRequired,
  currentName: PropTypes.string,
  onClose: PropTypes.func,
}

EditName.defaultProps = {
  onClose: null,
  currentName: '',
}

export default EditName
