import React from 'react'

import { Skeleton, Stack } from '@mui/material'

export const PreloaderNewLesson = () => {
  return (
    <Stack direction="row" spacing={2}>
      <Stack direction="column" width="50%">
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </Stack>
      <Stack width="50%" pt={0.7}>
        <Skeleton variant="rectangular" sx={{ height: '230px' }} />
      </Stack>
    </Stack>
  )
}
