import bn from 'bignumber.js'

// Speech to text full package

const process1h = bn(84000) // tokens

export const estimateAudioHours = (tokens) =>
  bn(tokens).div(process1h).integerValue().toNumber()

export const estimateAudioMinutes = (tokens) =>
  bn(tokens).div(process1h.div(60)).integerValue().toNumber()

export const audioToTokens = (sec) =>
  bn(sec).times(process1h).div(3600).integerValue().toNumber()

// Translation

export const estimateTranslationCharactersK = (tokens) =>
  bn(tokens).div(1000).integerValue().toNumber()

export const estimateTranslationCharacters = (tokens) =>
  bn(tokens).integerValue().toNumber()
