import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Button, IconButton, CircularProgress } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { useDeviceContext } from '../contexts/device'

const ActionButton = ({ children, icon, cta, href, onClick, isLoading }) => {
  const { isMobile } = useDeviceContext()

  const LinkComponent = href && href.startsWith('mailto:') ? 'a' : null
  const actionProps = _.omitBy({ LinkComponent, href, onClick }, _.isNil)

  /* eslint-disable react/jsx-props-no-spreading */

  if (isMobile) {
    return (
      <IconButton {...actionProps} color={cta ? 'primary' : 'default'}>
        {isLoading ? <CircularProgress size="1.25rem" /> : icon}
      </IconButton>
    )
  }

  const styleProps = {
    variant: cta ? 'outlined' : 'text',
    startIcon: icon,
    size: cta ? 'medium' : 'small',
  }

  if (onClick) {
    if (icon) {
      styleProps.loadingPosition = 'start'
    }
    return (
      <LoadingButton onClick={onClick} loading={isLoading} {...styleProps}>
        {children}
      </LoadingButton>
    )
  }

  return (
    <Button {...actionProps} {...styleProps}>
      {children}
    </Button>
  )
}

ActionButton.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  cta: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
}

ActionButton.defaultProps = {
  cta: false,
  href: null,
  onClick: null,
  isLoading: false,
  icon: null,
}

export default ActionButton
