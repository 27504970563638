import _ from 'lodash'
import { useState, useEffect, useMemo } from 'react'
import { usePlayerContext } from '../contexts/player'

/** Extracted logic from usePlayer, to do not trigger context rerendering
 * @param {Array<{ id: string, offset: number}>} sentences - The list of sentences as a timeline
 */
const useContentTimeline = (sentences) => {
  const { player } = usePlayerContext()
  const [currentSentence, setCurrentSentence] = useState(null)

  const timeline = useMemo(
    () =>
      _(sentences)
        .reject((s) => _.isNil(s.offset))
        .map((s) => _.pick(s, ['id', 'offset']))
        .value(),
    [sentences]
  )

  // Track currently playing sentence
  useEffect(() => {
    if (!player || !timeline) return () => {}

    const showCurrentPosition = () => {
      const pos = player.seek()
      const current = _.find(timeline, (sentence, index) => {
        if (index === timeline.length - 1) {
          // If we're comparing the last fragment, it must be playing if none before were
          return true
        }
        // If the current fragment's offset is before or at the position, and the next is after, the current fragment is playing
        return sentence.offset <= pos && timeline[index + 1].offset > pos
      })
      setCurrentSentence(current.id)
      if (player.playing()) {
        setTimeout(showCurrentPosition, 200)
      }
    }
    player.on('play', showCurrentPosition)
    return () => player.off('play', showCurrentPosition)
  }, [player, timeline])

  return { currentSentence }
}

export default useContentTimeline
