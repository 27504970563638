import React from 'react'
import { Button } from '@mui/material'
import { useProfileContext } from '../../contexts/profile'

const BillingPageButton = () => {
  const { isTrial } = useProfileContext()
  return (
    <Button
      color="inherit"
      href={`/billing?backUrl=${window.location.pathname}`}
      sx={{ whiteSpace: 'nowrap' }}
    >
      {isTrial ? 'Subscribe' : 'Billing page'}
    </Button>
  )
}

export default BillingPageButton
