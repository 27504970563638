import React from 'react'
import PropTypes from 'prop-types'
import { FiXCircle, FiCheckCircle } from 'react-icons/fi'

import { Spinner } from '../../../../components'

const UploadStatusOverlay = ({ uploadStatus }) => {
  const { status, progress, message } = uploadStatus
  if (!status) return null

  switch (status) {
    case 'uploaded':
      return (
        <div className="upload-status-overlay">
          <div className="status-icon status-icon-success">
            <FiCheckCircle />
          </div>
        </div>
      )
    case 'failed':
      return (
        <div className="upload-status-overlay">
          <div className="status-icon status-icon-error">
            <FiXCircle />
          </div>
          {message}
        </div>
      )

    case 'pending':
      return (
        <div className="upload-status-overlay">
          <Spinner />
          {`Uploading... ${progress}%`}
        </div>
      )

    default:
      return null
  }
}

export const UploadStatusType = PropTypes.shape({
  status: PropTypes.string,
  progress: PropTypes.number,
  message: PropTypes.string,
})

UploadStatusOverlay.propTypes = { uploadStatus: UploadStatusType.isRequired }

export default UploadStatusOverlay
