import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'

export function useDevice() {
  const [isMobile, setMobile] = useState(false)
  const {
    breakpoints: {
      values: { tablet: tabletWidth },
    },
  } = useTheme()

  useEffect(() => {
    const checkDevice = () => {
      setMobile(Number(window.innerWidth) < tabletWidth)
    }
    checkDevice()
    window.addEventListener('resize', checkDevice)
    return () => {
      window.removeEventListener('resize', checkDevice)
    }
  }, [tabletWidth])

  return { isMobile }
}
