import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

// Hooks and utils
import { getAuth, isSignInWithEmailLink } from 'firebase/auth'
import { useProfileContext } from '../contexts/profile'

// Pages
import Dashboard from './Dashboard'
import LessonCreate from './LessonCreate'
import LessonView from './LessonView'
import Highlights from './Highlights'
import Signin from './Signin'
import SigninConfirm from './SigninConfirm'
import Profile from './Profile'
import PrivacyPolicy from './PrivacyPolicy'
import LicenseAgreement, { isLicenseAccepted } from './LicenseAgreement'
import Billing from './Billing'
import Checkout from './Checkout'
import { LessonHelp } from './Help'

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#usage-with-routes--component
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const Router = () => {
  const profile = useProfileContext()

  const { isLoggedIn, isOnboarded, licenseAcceptedAt } = profile

  if (!isLoggedIn) return <Anonymous />
  if (!isOnboarded) return <Profile />
  if (!isLicenseAccepted(licenseAcceptedAt)) return <LicenseAgreement />

  return <Authenticated />
}

const Anonymous = () => {
  if (isSignInWithEmailLink(getAuth(), window.location.href)) {
    return <SigninConfirm />
  }
  return (
    <SentryRoutes>
      <Route path="privacy" element={<PrivacyPolicy />} />
      <Route path="license" element={<LicenseAgreement />} />
      <Route path="login" element={<Signin />} />
      <Route path="signup" element={<Signin isNewAccount />} />
      <Route path="*" element={<Signin />} />
    </SentryRoutes>
  )
}

const Authenticated = () => (
  <SentryRoutes>
    <Route path="/" element={<Dashboard />} />
    <Route path="login" element={<Navigate to="/" />} />
    <Route path="signup" element={<Navigate to="/" />} />
    <Route path="privacy" element={<PrivacyPolicy />} />
    <Route path="license" element={<LicenseAgreement />} />
    <Route path="profile" element={<Profile />} />
    <Route path="billing" element={<Billing />} />
    <Route path="new">
      <Route index element={<LessonCreate />} />
    </Route>
    <Route path="lesson">
      <Route path=":id" element={<LessonView />} />
    </Route>
    <Route path="highlights" element={<Highlights />} />
    <Route path="checkout">
      <Route path=":id" element={<Checkout />} />
    </Route>
    <Route path="help">
      <Route path="lesson" element={<LessonHelp />} />
    </Route>
  </SentryRoutes>
)

export default Router
