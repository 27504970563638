import React from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import ActionButton from '../../../components/ActionButton'

const HelpButton = () => {
  return (
    <ActionButton icon={<HelpOutlineIcon />} href="/help/lesson">
      How to learn
    </ActionButton>
  )
}

export default HelpButton
