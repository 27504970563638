import { styled } from '@mui/system'

export const DocumentBox = styled('section')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: theme.spacing(2),

  '& > img': {
    width: '100%',
    border: '1px solid grey',
  },

  [theme.breakpoints.down('tablet')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))
