import React from 'react'
import PropTypes from 'prop-types'
import LogoutIcon from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'
import { getAuth, signOut } from 'firebase/auth'

import { errorCapture } from '../../../utils/errorCapture'
import ActionButton from '../../../components/ActionButton'

const SignoutButton = ({ noIcon }) => {
  const navigate = useNavigate()
  const icon = noIcon ? null : <LogoutIcon />

  const onClick = async () => {
    try {
      await signOut(getAuth())
      navigate('/')
    } catch (error) {
      errorCapture(error)
    }
  }

  return (
    <ActionButton onClick={onClick} icon={icon}>
      Sign out
    </ActionButton>
  )
}

SignoutButton.propTypes = {
  noIcon: PropTypes.bool,
}

SignoutButton.defaultProps = {
  noIcon: false,
}

export default SignoutButton
