import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { deleteDoc } from 'firebase/firestore'

import {
  ListItemIcon,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import { lessonRef } from '../../../../configs/firestore'
import { errorCapture } from '../../../../utils/errorCapture'
import { useProfileContext } from '../../../../contexts/profile'

const DeleteLesson = ({ lessonId: id, onClose }) => {
  const navigate = useNavigate()
  const { uid } = useProfileContext()
  const [isPending, togglePending] = useState(false)

  const [open, setOpen] = useState(false)
  const handleClickOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    onClose && onClose()
  }

  const handleDeleteLesson = async () => {
    if (isPending) return
    try {
      togglePending(true)
      await deleteDoc(lessonRef({ uid, id }))
      navigate('/')
    } catch (error) {
      errorCapture(error)
      togglePending(false)
    }
    handleClose()
  }

  return (
    <>
      <MenuItem onClick={handleClickOpen} disabled={isPending}>
        <ListItemIcon>
          <DeleteIcon color="error" fontSize="small" />
        </ListItemIcon>
        <Typography variant="overline" color="error">
          Delete
        </Typography>
      </MenuItem>
      <Dialog open={open} onClose={handleClose} maxWidth="mobile">
        <DialogTitle>Delete the lesson?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            It will delete all content associated with the lesson from your
            account.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Keep it
          </Button>
          <Button color="error" onClick={handleDeleteLesson} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

DeleteLesson.propTypes = {
  lessonId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

DeleteLesson.defaultProps = {
  onClose: null,
}

export default DeleteLesson
