import React from 'react'
import _ from 'lodash'

import { Stack, CircularProgress, Box } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'

import MainLayout, { BackButton } from '../../layouts/MainLayout'
import useCollection from '../../hooks/useCollection'

import NewLessonButton from './components/NewLessonButton'
import ProfileButton from './components/ProfileButton'
import FeedbackButton from './components/FeedbackButton'
import HighlightsButton from './components/HighlightsButton'
import LessonInfo from './components/LessonInfo'

const Dashboard = () => {
  const {
    docs: documents,
    isLoading,
    loadMoreRef,
  } = useCollection({
    name: 'feed',
    chunkSize: 50,
  })

  return (
    <MainLayout
      actions={
        <>
          <FeedbackButton />
          <ProfileButton />
          <HighlightsButton />
          <NewLessonButton />
        </>
      }
      backButton={<BackButton disabled icon={<HomeIcon />} />}
      noWrap
    >
      <div>
        {documents.map((doc) => (
          <LessonInfo data={doc} sx={{ mb: 1 }} />
        ))}
        {isLoading ? (
          <Stack margin={2} direction="row" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          !_.isEmpty(documents) && (
            <Box ref={loadMoreRef} sx={{ height: '1px', width: '100%' }} />
          )
        )}
      </div>
    </MainLayout>
  )
}

export default Dashboard
