// https://stackoverflow.com/questions/71118040/getting-the-duration-of-an-mp3-file-in-a-variable
export function getAudioDuration(file) {
  const audio = document.createElement('audio')
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      audio.src = e.target.result
      audio.addEventListener(
        'loadedmetadata',
        () => {
          // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
          resolve(audio.duration)
        },
        false
      )
    }
    reader.readAsDataURL(file)
  })
}
