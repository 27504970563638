import _ from 'lodash'
import { useEffect, useReducer, useState, useCallback, useRef } from 'react'
import { getDocs, query, orderBy, startAfter, limit } from 'firebase/firestore'

import { errorCapture } from '../utils/errorCapture'
import { useProfileContext } from '../contexts/profile'
import { profileSubCollection } from '../configs/firestore'

export default function useColection({ name, chunkSize }) {
  const { uid } = useProfileContext()
  const [docs, dispatch] = useReducer((state, action) => {
    return _.unionWith(state, action.payload, (a, b) => a.id === b.id)
  }, [])

  const addLoadedDocs = (snap) => {
    dispatch({
      type: 'load',
      payload: _(snap.docs)
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .value(),
    })
  }

  const [lastDoc, setLastDoc] = useState(null)
  const loadMoreRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!name) return
    getDocs(
      query(
        profileSubCollection(uid, name),
        orderBy('createdAt', 'desc'),
        limit(chunkSize)
      )
    )
      .then((snap) => {
        addLoadedDocs(snap)
        setLastDoc(_.last(snap.docs))
        setIsLoading(false)
      })
      .catch(errorCapture)
  }, [uid, name, chunkSize])

  const onLoadMore = useCallback(() => {
    if (!lastDoc || isLoading) return
    setIsLoading(true)
    getDocs(
      query(
        profileSubCollection(uid, name),
        orderBy('createdAt', 'desc'),
        startAfter(lastDoc),
        limit(chunkSize)
      )
    )
      .then((snap) => {
        addLoadedDocs(snap)
        setLastDoc(_.last(snap.docs))
        setIsLoading(false)
      })
      .catch(errorCapture)
  }, [uid, name, chunkSize, lastDoc, isLoading])

  // Set up the Intersection Observer
  useEffect(() => {
    if (!loadMoreRef.current) return () => {}

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0]
        if (entry.isIntersecting) {
          onLoadMore()
        }
      },
      { threshold: 1 }
    )

    observer.observe(loadMoreRef.current)

    return () => {
      observer.disconnect()
    }
  }, [loadMoreRef, onLoadMore])

  return { docs, isLoading, loadMoreRef }
}
