import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import AudiotrackIcon from '@mui/icons-material/Audiotrack'
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Typography,
  Chip,
} from '@mui/material'
import _ from 'lodash'

import { useProfileContext } from '../../../../contexts/profile'

function timeLeft(quota) {
  const duration = _.floor(_.max([_.get(quota, 'audio', 0)]))
  if (!duration) {
    return { status: 'noAudio' }
  }
  const minutes = duration / 60
  if (minutes < 30) {
    return { status: 'warning', text: `${_.floor(minutes)} min left` }
  }

  const hours = minutes / 60
  if (hours < 2) {
    return { status: 'default', text: `${_.floor(minutes)} min left` }
  }

  return { status: 'default', text: `>${_.floor(hours)}h left` }
}

const LessonFromAudio = ({ onSelect }) => {
  const inputEl = useRef(null)
  const onFileChange = (e) => {
    onSelect('audio', e.target.files)
  }

  const profile = useProfileContext()
  const { status, text } = timeLeft(profile.quota)

  return (
    <Card>
      <input
        ref={inputEl}
        style={{ display: 'none' }}
        type="file"
        accept=".mp3,.m4a,.mp4,.aac,.wav,.flac"
        onChange={onFileChange}
      />
      <CardActionArea onClick={() => inputEl.current.click()}>
        <CardHeader
          avatar={<AudiotrackIcon />}
          title="Upload an audio"
          titleTypographyProps={{ variant: 'h6' }}
          sx={{ pb: 0 }}
        />
        <CardContent>
          <Typography color="text.secondary">
            to get a transcript with interlinear translation
          </Typography>
          {status === 'noAudio' ? null : (
            <Chip label={text} color={status} sx={{ mt: 2 }} />
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
LessonFromAudio.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default LessonFromAudio
