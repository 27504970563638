import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import * as types from '../../../types'
import { HighlightsContextProvider } from './Highlights'
import { Sentence } from './Sentence'
import useContentTimeline from '../../../hooks/useContentTimeline'

const Content = ({ name, sentences }) => {
  const { currentSentence } = useContentTimeline(sentences)
  return (
    <HighlightsContextProvider name={name} sentences={sentences}>
      {_.map(sentences, (s) => (
        <Sentence key={s.id} current={s.id === currentSentence} content={s} />
      ))}
    </HighlightsContextProvider>
  )
}

Content.propTypes = {
  name: PropTypes.string.isRequired,
  sentences: PropTypes.arrayOf(types.Sentence).isRequired,
}

export default Content
