import React from 'react'
import PropTypes from 'prop-types'
import ScaleLoader from 'react-spinners/ScaleLoader'

import './Spinner.css'

const Spinner = ({ height, color }) => (
  <div className="spinner">
    <ScaleLoader height={height} color={color} />
  </div>
)

Spinner.propTypes = {
  height: PropTypes.number,
  color: PropTypes.string,
}
Spinner.defaultProps = {
  height: undefined,
  color: '#000000',
}

export default Spinner
