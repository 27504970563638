import { styled } from '@mui/system'
import { Container, Stack } from '@mui/material'

export const PageWrapper = styled(Container)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  maxWidth: theme.breakpoints.values.mobile,
  position: 'relative',
  height: '100%',
  display: 'flex',
}))

export const Header = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  padding: theme.spacing(2),
  alignItems: 'center',
}))
