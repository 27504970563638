import PropTypes from 'prop-types'

export const UploadedFile = PropTypes.shape({
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
})

export const UploadedFileReady = PropTypes.shape({
  originalName: PropTypes.string.isRequired,
  mimeType: PropTypes.string.isRequired,
  blob: PropTypes.instanceOf(Blob),
})

export const UploadedFileDoc = PropTypes.shape({
  id: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  sourceFile: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  parsedText: PropTypes.arrayOf(PropTypes.string), // Result we for from Vision API
  sentences: PropTypes.arrayOf(PropTypes.string), // LEGACY edited content split to sentences
  content: PropTypes.string, // Edited content
  pageNumber: PropTypes.number,
  locale: PropTypes.string,
})

export const Token = PropTypes.shape({
  lemma: PropTypes.string,
  tag: PropTypes.string,
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
})

export const Sentence = PropTypes.shape({
  id: PropTypes.string.isRequired,
  original: PropTypes.string,
  lang: PropTypes.string,
  tokens: PropTypes.arrayOf(Token),
  translation: PropTypes.string, // added in useLesson hook
  translationLanguage: PropTypes.string,
  offset: PropTypes.number,
})

export const Lesson = PropTypes.shape({
  id: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'image', 'audio']).isRequired,
  name: PropTypes.string.isRequired,
  sentences: PropTypes.arrayOf(Sentence),
})
