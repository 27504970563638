import { styled } from '@mui/system'
import { LinearProgress } from '@mui/material'

const Loader = styled(LinearProgress)({
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: 1000,
})

export default Loader
