import { useState, useEffect } from 'react'
import _ from 'lodash'
import { onSnapshot, getDoc } from 'firebase/firestore'

import * as languages from '../utils/languages'
import { lessonRef, contentRef } from '../configs/firestore'
import { useProfileContext } from '../contexts/profile'

const getTranslationLanguage = (lang, lesson) => {
  const translations = _.values(_.get(lesson, 'translations', {}))
  if (!translations.length) return lang
  return (
    languages.select(
      lang,
      translations.map((tr) => ({
        code: tr.language,
      }))
    ) || translations[0].language
  )
}

export const tokenize = (sentence) => {
  let { tokens } = sentence
  if (_.isEmpty(tokens)) {
    tokens = sentence.original
      .match(/\s*[\p{L}\p{N}]+|\s*[^\s\p{L}\p{N}]+/gu)
      .map((token) => {
        return { text: _.trim(token), sb: token.match(/^\s/) ? ' ' : '' }
      })
  }
  return { ...sentence, tokens: tokens.map((t, i) => ({ ...t, id: `t${i}` })) }
}

export default function useLesson({ id, uid }) {
  const { nativeLanguage } = useProfileContext()
  const [data, setData] = useState({ id })
  const [isLoading, toggleLoading] = useState(true)

  const translationLanguage = getTranslationLanguage(nativeLanguage, data)

  useEffect(() => {
    if (!id) return () => {}

    let unsubscribe = () => {}
    getDoc(lessonRef({ uid, id })).then((lesson) => {
      const ref = lesson.exists() ? lesson.ref : contentRef({ id })
      unsubscribe = onSnapshot(ref, (snapshot) => {
        if (snapshot.exists()) {
          setData({ ...snapshot.data(), id })
          toggleLoading(false)
        }
      })
    })

    return unsubscribe
  }, [id, uid])

  if (_.size(data.sentences) > 0) {
    data.sentences = _(data.sentences)
      .reject((s) => _.isEmpty(s.original))
      .map((s) => ({
        ...tokenize(s),
        translation: s[translationLanguage],
        lang: data.lang,
      }))
      .value()
    // add endOffset to audio sentences
    if (data.type === 'audio') {
      let endOffset = data.duration + 1
      data.sentences = data.sentences
        .reverse()
        .map((s) => {
          if (_.isNil(s.offset)) return s
          const timed = { ...s, endOffset }
          endOffset = s.offset
          return timed
        })
        .reverse()
    }
  }

  return { ...data, translationLanguage, isLoading }
}
