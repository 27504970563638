import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore'
import _ from 'lodash'
import { app } from './firebase'

export const getProducts = async () => {
  const productsSnap = await getDocs(
    query(
      collection(getFirestore(app), 'products'),
      where('active', '==', true)
    )
  )
  const product = _(productsSnap.docs)
    .filter(
      (doc) =>
        doc.get('metadata.type') === 'subscription' &&
        doc.get('metadata.label') === 'recommended'
    )
    .map((doc) => ({ id: doc.id, ...doc.data() }))
    .first()
  const pricesSnap = await getDocs(
    query(
      collection(getFirestore(app), 'products', product.id, 'prices'),
      where('active', '==', true)
    )
  )
  return (
    _(pricesSnap.docs)
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      // Flatten price and product description
      // NOTE we need priceId to create checkout session
      .map((p) => ({
        id: p.id,
        name: product.name,
        description: product.description,
        tokens: Number(product.metadata.tokens),
        price: p.unit_amount,
        currency: p.currency,
        interval: p.interval,
      }))
      .sortBy('-price')
      .value()
  )
}
