import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { IconButton } from '@mui/material'
import ArrowBack from '@mui/icons-material/ArrowBack'

const BackButton = ({ disabled, to, icon, onClick }) => {
  const navigate = useNavigate()
  // Use explicit onClick handler unless button is disabled
  let action = disabled ? null : onClick
  // Navigate to a location by default, unless disabled
  if (!action && !disabled)
    action = () => {
      navigate(to)
    }
  return (
    <IconButton disabled={disabled} onClick={action} sx={{ ml: -1 }}>
      {icon || <ArrowBack />}
    </IconButton>
  )
}

BackButton.propTypes = {
  disabled: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.node,
  onClick: PropTypes.func,
}

BackButton.defaultProps = {
  disabled: false,
  to: -1,
  icon: null,
  onClick: null,
}

export default BackButton
