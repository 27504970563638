import React from 'react'
import PropTypes from 'prop-types'
import { Stack } from '@mui/material'
import { HeaderStack, HeaderTitle } from './styles'
import { Section } from '../../styles'

const Header = ({ children, title, backButton }) => (
  <HeaderStack component="header">
    <Section>
      <aside>{backButton}</aside>
      <Stack direction="row" justifyContent="space-between" minHeight={40}>
        <HeaderTitle>{title}</HeaderTitle>
        {children && (
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            {children}
          </Stack>
        )}
      </Stack>
    </Section>
  </HeaderStack>
)

Header.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  backButton: PropTypes.node,
}

Header.defaultProps = {
  children: null,
  backButton: null,
  title: '',
}

export default Header
