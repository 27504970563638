import { styled } from '@mui/system'
import { grey } from '@mui/material/colors'
import { Container } from '@mui/material'

export const Wrapper = styled(Container)(({ theme }) => ({
  position: 'relative',
  maxWidth: theme.breakpoints.values.tablet,
  padding: 0,
  display: 'grid',
  gap: theme.spacing(2),

  main: {
    padding: theme.spacing(0, 2, 2),
  },
}))

export const Section = styled('section')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: `4rem 1fr`,
  paddingRight: theme.spacing(2),
  width: '100%',
  position: 'relative',

  // Icon, button, timestamp to look all aligned
  '> aside': {
    display: 'flex',
    justifyContent: 'center',
    color: grey[600],
    position: 'relative',
    alignItems: 'flex-start',

    '@media print': {
      display: 'block',
      svg: {
        display: 'none',
      },
    },
  },
}))

export const SectionWithSides = styled(Section)(({ theme }) => ({
  gridTemplateColumns: `4rem 1fr 4rem`,
  paddingRight: 0,
  [theme.breakpoints.down('tablet')]: {
    gridTemplateColumns: '4rem 1fr 0',
    paddingRight: theme.spacing(2),
    '> :last-child': {
      display: 'none',
    },
  },
}))
