import React from 'react'
import { Alert, AlertTitle } from '@mui/material'
import BillingPageButton from './BillingPageButton'

const ImageToTextQuotaAlert = () => {
  return (
    <Alert severity="error" action={<BillingPageButton />}>
      <AlertTitle>Not enough quota to process all images</AlertTitle>
      Please check your quota and subscription status on the billing page.
    </Alert>
  )
}

export default ImageToTextQuotaAlert
