import { styled } from '@mui/system'
import { Stack } from '@mui/material'

const LessonFormStack = styled(Stack)(({ theme }) => ({
  rowGap: theme.spacing(2),
  width: '100%',
  zIndex: 10,

  [theme.breakpoints.down('tablet')]: {
    paddingTop: theme.spacing(1),
  },
}))

export default LessonFormStack
