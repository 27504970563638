import { styled } from '@mui/system'

export const Article = styled('article')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  p: {
    margin: theme.spacing(2, 0),
  },
  'h4, h5': {
    margin: theme.spacing(4, 0, 2, 0),
  },
}))
