import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import ImageIcon from '@mui/icons-material/Image'
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material'

import heic2jpeg from './heic2jpeg'

const LessonFromImage = ({ onSelect }) => {
  const inputEl = useRef(null)
  const callback = _.curry(onSelect)('image')

  const onFileChange = (e) => {
    // Return choosen type and empty list of files to immediately show
    // lesson edit form while still waiting for image files to be converted
    callback([])
    // Call onSelect again with ready files
    heic2jpeg(e.target.files).then(callback)
  }

  return (
    <Card>
      <input
        ref={inputEl}
        style={{ display: 'none' }}
        type="file"
        accept="image/*,.heic"
        multiple
        onChange={onFileChange}
      />
      <CardActionArea onClick={() => inputEl.current.click()}>
        <CardHeader
          avatar={<ImageIcon />}
          title="Take a photo"
          titleTypographyProps={{ variant: 'h6' }}
          sx={{ pb: 0 }}
        />
        <CardContent>
          <Typography color="text.secondary">
            to convert a printed page into a bilingual text
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

LessonFromImage.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default LessonFromImage
