import React from 'react'
import { UploadedFile } from '../../../../types'

/**
 * Display a selected audio file
 *
 * @prop {Object} audio Selected file
 */
const PreviewAudio = ({ audio }) => {
  return <b>{audio.name}</b>
}

PreviewAudio.propTypes = {
  audio: UploadedFile.isRequired,
}

export default PreviewAudio
