import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Alert, AlertTitle } from '@mui/material'
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth'

import { emailCacheKey } from '../../configs/auth'
import EmailForm from '../../components/EmailForm'
import AuthLayout from '../../layouts/AuthLayout'
import { FadedText } from './styles'
import { useAffiliate } from '../../hooks/useAffiliate'

const Signin = ({ isNewAccount }) => {
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState('')
  const { url } = useAffiliate()
  const title = isNewAccount ? 'Sign up' : 'Log in'
  const cta = isNewAccount ? 'Signup' : 'Login'

  const onSubmit = ({ email: _email }) =>
    sendSignInLinkToEmail(getAuth(), _email, {
      url: `${url}`,
      // This must be true.
      handleCodeInApp: true,
    })
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem(emailCacheKey, _email)
        setStatus('success')
        setEmail(_email)
      })
      .catch((error) => {
        // TODO report the error
        setStatus('error')
      })

  switch (status) {
    case 'success':
      return (
        <AuthLayout title={title} subtitle="Get access with an email">
          <Alert severity="info">
            <AlertTitle>Check your inbox</AlertTitle>
            We sent you an email with a login link to <strong>{email}</strong>.
            Please, follow instructions to authenticate your profile.
          </Alert>
        </AuthLayout>
      )

    case 'error':
      return (
        <AuthLayout title={title} subtitle="Get access with an email">
          <Alert severity="error">
            <AlertTitle>Something went wrong</AlertTitle>
            We cannot send you the email to authenticate you. Please, try to
            refresh the page and submit the form again.
          </Alert>
        </AuthLayout>
      )

    default:
      return (
        <AuthLayout title={title} subtitle="Get access with an email">
          <EmailForm buttonText={cta} onSubmit={onSubmit} />
          {isNewAccount ? (
            <FadedText>
              Already have an account? <Link to="/login">Log in</Link>
            </FadedText>
          ) : (
            <FadedText>
              Don&apos;t have an account yet? <Link to="/signup">Sign up</Link>
            </FadedText>
          )}
          <FadedText>
            Please read our <Link to="/privacy">Privacy Policy</Link> to learn
            how we process your data.
          </FadedText>
        </AuthLayout>
      )
  }
}

Signin.propTypes = {
  isNewAccount: PropTypes.bool,
}

Signin.defaultProps = {
  isNewAccount: false,
}

export default Signin
