import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { Stack } from '@mui/material'

import { useProfileContext } from '../../contexts/profile'
import MainLayout, { BackButton } from '../../layouts/MainLayout'
import Plans from './components/Plans'
import Summary from './components/Summary'

const Billing = () => {
  const [params] = useSearchParams()
  const { isLoading, subscription } = useProfileContext()

  return (
    <MainLayout
      title="Billing"
      backButton={<BackButton to={params.get('backUrl') || '/'} />}
      isLoading={isLoading}
    >
      <Stack spacing={4} direction="column">
        <Summary />
        {
          // show plans only if user doesn't have a subscription or it's canceled
          (!subscription.status || subscription.status === 'canceled') && (
            <Plans />
          )
        }
      </Stack>
    </MainLayout>
  )
}

export default Billing
