import React from 'react'
import PropTypes from 'prop-types'
import { Paper, Typography } from '@mui/material'
import _ from 'lodash'

const DocumentContent = ({ content }) => {
  return (
    <Paper elevation={0}>
      {content.map(({ header, paragraphs }) => (
        <>
          {header && (
            <Typography variant="h6" fontWeight={600}>
              {header}
            </Typography>
          )}
          {_(paragraphs)
            .flattenDeep()
            .map((p) => (
              <Typography mt={2} mb={2}>
                {p}
              </Typography>
            ))
            .value()}
        </>
      ))}
    </Paper>
  )
}

const Paragraph = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
])

const Chapter = PropTypes.shape({
  header: PropTypes.string,
  paragraphs: PropTypes.arrayOf(Paragraph),
})

DocumentContent.propTypes = {
  content: PropTypes.arrayOf(Chapter),
}

DocumentContent.defaultProps = {
  content: [],
}

export default DocumentContent
