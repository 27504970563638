import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
} from '@mui/material'

import * as languages from '../../utils/languages'
import { SupLabel } from './styles'

const LanguageSelect = ({
  value,
  onChange,
  disabled,
  variant,
  error,
  labelText,
}) => {
  const langs = _.get(languages, variant, [])
  const selectedValue = languages.select(value, langs)

  useEffect(() => {
    onChange(selectedValue)
  }, [value, selectedValue, onChange])

  return (
    <FormControl error={!!error} size="small">
      <InputLabel id="language-select-label">{labelText}</InputLabel>
      <Select
        label={labelText}
        labelId="language-select-label"
        onChange={(e) => {
          onChange(e.target.value)
        }}
        displayEmpty
        value={selectedValue}
        disabled={disabled}
        required
      >
        {langs.map(({ code, name, label }) => (
          <MenuItem key={code} value={code}>
            {name}
            {label && <SupLabel>{label}</SupLabel>}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

LanguageSelect.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'image', 'audio']),
  labelText: PropTypes.string,
}

LanguageSelect.defaultProps = {
  value: '',
  error: '',
  disabled: false,
  variant: 'text',
  labelText: 'Language',
}

export default LanguageSelect
