import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions'
import { app } from './firebase'

export const functions = getFunctions(app, 'europe-west3')
if (process.env.REACT_APP_USE_LOCAL_FUNCTIONS) {
  connectFunctionsEmulator(functions, 'localhost', 5001)
}

export const claimTrial = httpsCallable(functions, 'quota-claimTrial')
export const convertAudioQuota = httpsCallable(functions, 'quota-convert')
export const createCheckoutSession = httpsCallable(
  functions,
  'stripe-createCheckoutSession'
)
export const getPortalUrl = httpsCallable(functions, 'stripe-createPortalLink')
export const parseAudioLesson = httpsCallable(functions, 'lesson-parse')
export const shareLesson = httpsCallable(functions, 'lesson-share-api')
