import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl, Stack, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

const isEmailValid = (value) =>
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    value || ''
  )

const EmailForm = ({ buttonText, onSubmit: callback, disabled }) => {
  const [email, setEmail] = useState('')
  const isValid = isEmailValid(email)
  const [isPending, togglePending] = useState(false)

  const onEmailChange = (event) => {
    setEmail(event.target.value)
  }
  const onSubmit = async (event) => {
    event.preventDefault()
    if (isPending || disabled) return
    togglePending(true)
    callback({ email })
  }

  return (
    <FormControl
      component="form"
      onSubmit={onSubmit}
      sx={{ width: '100%' }}
      disabled={disabled}
    >
      <Stack direction="row" spacing={2}>
        <TextField
          id="email"
          type="email"
          disabled={isPending}
          label="Email address"
          onChange={onEmailChange}
          fullWidth
          required
          focused
          size="small"
        />
        <LoadingButton
          type="submit"
          variant="contained"
          disabled={disabled || !isValid || isPending}
          loading={isPending}
        >
          {buttonText}
        </LoadingButton>
      </Stack>
    </FormControl>
  )
}

EmailForm.propTypes = {
  buttonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
EmailForm.defaultProps = {
  disabled: false,
}

export default EmailForm
