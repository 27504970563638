import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@mui/material'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'
import bn from 'bignumber.js'
import _ from 'lodash'
import { usePlayerContext } from '../../../../contexts/player'

const Offset = ({ current, offset }) => {
  const { play, togglePlay, isPlaying } = usePlayerContext()
  const playPause = useCallback(
    () => (current && isPlaying ? togglePlay() : play(offset)),
    [offset, play, togglePlay, current, isPlaying]
  )

  const { min, sec, dt } = useMemo(() => {
    if (!_.isNumber(offset)) return { min: '00', sec: '00', dt: '00:00' }
    const m = bn(offset)
      .dividedBy(60)
      .toFixed(0, bn.ROUND_DOWN)
      .padStart(2, '0')
    const s = bn(offset).mod(60).toFixed(0, bn.ROUND_DOWN).padStart(2, '0')
    return { min: m, sec: s, dt: `${m}:${s}` }
  }, [offset])

  if (!_.isNumber(offset)) return null

  /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
  return (
    <>
      <IconButton className="play-button" onClick={playPause} disableRipple>
        {current && isPlaying ? (
          <PauseCircleOutlineIcon />
        ) : (
          <PlayCircleOutlineIcon />
        )}
      </IconButton>
      <time dateTime={dt}>
        <span>
          {min}:{sec}
        </span>
      </time>
    </>
  )
}

Offset.propTypes = {
  current: PropTypes.bool.isRequired,
  offset: PropTypes.number,
}

Offset.defaultProps = {
  offset: null,
}

export default Offset
