import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, CircularProgress } from '@mui/material'
import TranslateIcon from '@mui/icons-material/Translate'

const TranslationSwitch = ({ status, checked, onToggle }) => {
  const hasTranslation = status === 'finished'
  return (
    <IconButton
      aria-label={checked ? 'Hide translation' : 'Show translation'}
      onClick={() => onToggle(!checked)}
      disabled={!hasTranslation}
    >
      {!hasTranslation && <CircularProgress sx={{ position: 'absolute' }} />}
      <TranslateIcon
        color={hasTranslation && checked ? 'primary' : 'default'}
      />
    </IconButton>
  )
}

TranslationSwitch.propTypes = {
  status: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default TranslationSwitch
