import React from 'react'
import { Typography, Paper } from '@mui/material'

import { useProfileContext } from '../../../contexts/profile'
import {
  estimateTranslationCharacters,
  estimateAudioMinutes,
} from '../../../utils/tokenomics'

const Tokens = () => {
  const { quota } = useProfileContext()
  const tokens = Number(quota?.tokens || 0)
  if (!tokens) return null

  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Typography variant="h6">
        <b>{tokens.toLocaleString()}</b> tokens left
      </Typography>
      <p>It will be enouth to:</p>
      <ul>
        <li>
          transcribe{' '}
          <b>{estimateAudioMinutes(tokens).toLocaleString()} minutes</b> of
          audio; or
        </li>
        <li>
          translate{' '}
          <b>
            {estimateTranslationCharacters(tokens).toLocaleString()} characters
          </b>
        </li>
      </ul>
    </Paper>
  )
}

export default Tokens
