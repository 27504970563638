import React from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ActionButton from '../../../components/ActionButton'

const NewLessonButton = () => (
  <ActionButton cta href="/new" icon={<AddCircleOutlineIcon />}>
    Add content
  </ActionButton>
)

export default NewLessonButton
