import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const CopyButton = ({ text }) => {
  const onClick = useCallback(() => {
    navigator.clipboard.writeText(text)
  }, [text])
  return (
    <IconButton
      className="copy-button"
      onClick={onClick}
      aria-label="Copy to clipboard"
    >
      <ContentCopyIcon />
    </IconButton>
  )
}

CopyButton.propTypes = {
  text: PropTypes.string.isRequired,
}

export default CopyButton
