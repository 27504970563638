import bn from 'bignumber.js'
import { useState, useEffect } from 'react'
import { usePlayerContext } from '../contexts/player'

// Extracted logic from usePlayer, to do not trigger context rerendering
const usePlaybackProgress = () => {
  const { duration, player } = usePlayerContext()
  const [position, setPosition] = useState(0)

  useEffect(() => {
    if (!player) return () => {}
    const checkCurrentPosition = () => {
      const pos = player.seek()
      setPosition(pos)
      if (player.playing()) {
        setTimeout(checkCurrentPosition, 200)
      }
    }
    player.on('play', checkCurrentPosition)
    return () => player.off('play', checkCurrentPosition)
  }, [player])

  const progress = bn(position).div(duration).times(100).toNumber()

  return { duration, position, progress }
}

export default usePlaybackProgress
