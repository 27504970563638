import React from 'react'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import ActionButton from '../../../components/ActionButton'

const ProfileButton = () => (
  <ActionButton href="/profile" icon={<PermIdentityIcon />}>
    Profile
  </ActionButton>
)

export default ProfileButton
