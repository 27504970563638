import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { SectionWithSides } from '../../../../layouts/MainLayout/styles'

import * as types from '../../../../types'
import Highlighter from '../Highlights/Highlighter'
import { Offset } from '../Player'
import CopyButton from '../CopyButton'

const Sentence = ({ current, content: s }) => {
  if (_.size(s) === 0) return null

  return (
    <SectionWithSides className={current ? 'current' : null}>
      <aside>
        <Offset current={current} offset={s.offset} />
      </aside>
      <p>
        <Highlighter sentenceId={s.id} lang={s.lang} tokens={s.tokens} />
        {s.translation && <i>{s.translation}</i>}
      </p>
      <aside>
        <CopyButton text={s.original} />
      </aside>
    </SectionWithSides>
  )
}

Sentence.propTypes = {
  current: PropTypes.bool.isRequired,
  content: types.Sentence.isRequired,
}

export default Sentence
