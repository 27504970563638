import React from 'react'
import PropTypes from 'prop-types'
import Header from './components/Header'
import BackButton from './components/BackButton'
import PageWrapper from './components/PageWrapper'

const MainLayout = ({
  children,
  isLoading,
  title,
  actions,
  backButton,
  noBackButton,
  isPublic,
  noWrap,
  noHeaderTitle,
}) => {
  return (
    <PageWrapper isLoading={isLoading} isPublic={isPublic} title={title}>
      <Header
        title={noHeaderTitle ? '' : title}
        backButton={noBackButton ? null : backButton || <BackButton />}
      >
        {actions}
      </Header>
      {noWrap ? children : <main>{children}</main>}
    </PageWrapper>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  actions: PropTypes.node,
  backButton: PropTypes.node,
  noBackButton: PropTypes.bool,
  isPublic: PropTypes.bool,
  noWrap: PropTypes.bool,
  noHeaderTitle: PropTypes.bool,
}

MainLayout.defaultProps = {
  isLoading: false,
  title: 'Saanaru',
  actions: null,
  backButton: null,
  noBackButton: false,
  isPublic: false,
  noWrap: false,
  noHeaderTitle: false,
}

export default MainLayout
