import React, { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Typography, CardContent, Card, CardActions } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import RecyclingIcon from '@mui/icons-material/Recycling'
import _ from 'lodash'

import { useProfileContext } from '../../../contexts/profile'
import { audioToTokens } from '../../../utils/tokenomics'
import { convertAudioQuota } from '../../../configs/api'

const AudioQuota = () => {
  const [isPending, setPending] = useState(false)
  const profile = useProfileContext()
  const quota = _.get(profile, 'quota.audio', 0) // sec
  const hours = _.floor(quota / 3600)
  const seconds = _.ceil(quota - hours * 3600)
  const minutes = seconds < 60 ? 0 : _.ceil(seconds / 60)
  const licenseAt = _.get(profile, 'licenseAcceptedAt', null)

  const convertToTokens = useCallback(() => {
    if (isPending) return
    setPending(true)
    convertAudioQuota()
  }, [isPending])

  if (!quota) return null

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h6">Speech-to-text</Typography>
        <ul>
          <li>
            Prepaid{' '}
            <b>
              {hours > 0 && `${hours} h`}{' '}
              {minutes > 0 ? `${minutes} min` : `${seconds} sec`}
            </b>{' '}
            of audio transcription. The service is provided according to the{' '}
            <Link to="/license">licence agreement</Link> accepted on{' '}
            {licenseAt.toDate().toLocaleDateString()}.
          </li>
          <li>
            Convertable to <b>{audioToTokens(quota).toLocaleString()}</b> tokens
          </li>
        </ul>
      </CardContent>
      <CardActions sx={{ justifyContent: 'right' }}>
        <LoadingButton
          loading={isPending}
          color="warning"
          loadingPosition="start"
          startIcon={<RecyclingIcon />}
          onClick={convertToTokens}
        >
          Convert to tokens
        </LoadingButton>
      </CardActions>
    </Card>
  )
}

export default AudioQuota
