import React, { useState, useReducer } from 'react'
import CloseIcon from '@mui/icons-material/Close'

import MainLayout, { BackButton } from '../../layouts/MainLayout'
import LessonTypeSelect from './components/LessonTypeSelect'
import LessonForm from './components/LessonForm'
import { QuotasButton } from '../../components'

import './style.css'
import { useProfileContext } from '../../contexts/profile'

const initialState = {
  type: null,
  title: 'A new lesson',
}

function lessonTypeReducer(state, { type }) {
  switch (type) {
    case 'text':
      return { type, title: 'A new lesson from a text content' }
    case 'audio':
      return { type, title: 'A new lesson from an audio' }
    case 'image':
      return { type, title: 'A new lesson from a photo/scan' }
    default:
      return initialState
  }
}

const LessonCreate = () => {
  const {
    subscription: { status },
  } = useProfileContext()
  const [{ type, title }, dispatch] = useReducer(
    lessonTypeReducer,
    initialState
  )
  const [files, setFiles] = useState([])
  const onTypeAndFilesSelected = (selectedType, selectedFiles) => {
    dispatch({ type: selectedType })
    setFiles([...selectedFiles])
  }
  const dismiss = () => {
    dispatch({ type: null })
    setFiles([])
  }

  // Choose a lesson type first
  if (!type) {
    return (
      <MainLayout
        title={title}
        backButton={<BackButton to="/" icon={<CloseIcon />} />}
        actions={status !== 'active' ? <QuotasButton /> : null}
      >
        <LessonTypeSelect onSelect={onTypeAndFilesSelected} />
      </MainLayout>
    )
  }

  // Render lesson create form
  return (
    <MainLayout title={title} backButton={<BackButton onClick={dismiss} />}>
      <LessonForm type={type} files={files} onCancel={dismiss} />
    </MainLayout>
  )
}

export default LessonCreate
