import React, { useState, useRef, createRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { query, where, onSnapshot, updateDoc } from 'firebase/firestore'

import { Button, Stack, Alert } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import useLesson from '../../hooks/useLesson'
import useLessonParams from '../../hooks/useLessonParams'
import { useDeviceContext } from '../../contexts/device'
import { uploadsCollection, lessonRef } from '../../configs/firestore'
import { errorCapture } from '../../utils/errorCapture'

import { ImageToTextQuotaAlert, LessonFormStack } from '../../components'
import { UploadEdit } from './components/UploadEdit'
import { PreloaderNewLesson } from '../../components/PreloaderNewLesson'
import MainLayout, { BackButton } from '../../layouts/MainLayout'

function useUploads({ uid, id: lessonId, uploadIds }) {
  const [uploads, setUploads] = useState([])

  // Stringify IDs list to avoid calling useEffect with the same list of IDs
  // https://stackoverflow.com/questions/59467758/passing-array-to-useeffect-dependency-list
  const uploadIdsStr = JSON.stringify(uploadIds)
  // Get relevand uploaded docs
  useEffect(() => {
    const ids = JSON.parse(uploadIdsStr)
    if (_.isEmpty(ids)) return () => {}

    const uploadsQuery = query(
      uploadsCollection(uid),
      where('lessonId', '==', lessonId)
    )
    const unsubscribe = onSnapshot(uploadsQuery, (querySnapshot) => {
      const uploadsMap = _.chain(querySnapshot.docs)
        .map((doc) => {
          const data = doc.data()
          return [
            doc.id,
            { ...data, id: doc.id, pageNumber: Number(data.pageNumber) },
          ]
        })
        .fromPairs()
        .value()
      setUploads(_.map(ids, (id) => uploadsMap[id]))
    })

    return () => {
      unsubscribe()
    }
  }, [uploadIdsStr, uid, lessonId])

  return uploads
}

const LessonEditImage = () => {
  const { isMobile } = useDeviceContext()

  const navigate = useNavigate()
  const { id, uid } = useLessonParams()
  const data = useLesson({ id, uid })
  const { content: savedContent } = data

  const [isPending, togglePending] = useState(false)

  const uploadIds = data.uploadIds || []
  // Create references to get access to child documets to trigger save
  const elRefs = useRef({})
  if (_.size(elRefs.current) !== uploadIds.length) {
    elRefs.current = _.chain(uploadIds)
      .map((docId) => [docId, elRefs.current[docId] || createRef()])
      .fromPairs()
      .value()
  }
  const uploads = useUploads({ uid, id, uploadIds })
  const isParsed = _.every(uploads, ['status', 'parsed'])
  const noQuota =
    data.status === 'noQuota' || _.some(uploads, ['status', 'noQuota'])

  const onSaveButtonClick = async () => {
    togglePending(true)

    try {
      // update lesson from photo
      // TODO regactor this terrible solution which is based on calling child components
      const pages = await Promise.all(
        _.map(uploadIds, (docId) => elRefs.current[docId].current.save())
      )
      const content = _.chain(pages).join('\n\n').trim().value()
      if (content !== '') {
        await updateDoc(lessonRef({ uid, id }), {
          content,
        })
      }
      navigate(`/lesson/${id}`)
    } catch (error) {
      errorCapture(error)
    }
    togglePending(false)
  }

  if (!data.id) return null

  // TODO disable save button if nothing changed
  return (
    <MainLayout
      title={data.name}
      backButton={
        <BackButton icon={<CloseIcon />} to={savedContent ? -1 : '/'} />
      }
    >
      <LessonFormStack>
        {noQuota ? (
          <ImageToTextQuotaAlert />
        ) : (
          <Alert severity="warning">
            Please review parsed text and cleanup parsing artifacts.
          </Alert>
        )}

        {_.map(uploads, (doc) => {
          switch (doc.status) {
            case 'parsed':
            case 'noQuota':
              return (
                <UploadEdit
                  key={doc.id}
                  doc={doc}
                  ref={elRefs.current[doc.id]}
                  isMobile={isMobile}
                />
              )
            default:
              return (
                <div key={doc.id}>
                  <PreloaderNewLesson />
                  <p>Parsing uploaded image</p>
                </div>
              )
          }
        })}

        <Stack direction="row" justifyContent="flex-end">
          <Button
            variant="contained"
            disabled={isPending || !isParsed || noQuota}
            onClick={onSaveButtonClick}
          >
            Save
          </Button>
        </Stack>
      </LessonFormStack>
    </MainLayout>
  )
}

export default LessonEditImage
