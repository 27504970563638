import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

import { ProfileContextProvider } from './contexts/profile'
import { DeviceContextProvider } from './contexts/device'
import Router from './pages/Router'

function App() {
  return (
    <ProfileContextProvider>
      <DeviceContextProvider>
        <HelmetProvider>
          <Helmet>
            <title>Saanaru - Language lessons from podcasts and books</title>
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/icon/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/icon/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/icon/favicon-16x16.png"
            />
            <link rel="manifest" href="/site.webmanifest" />
          </Helmet>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </HelmetProvider>
      </DeviceContextProvider>
    </ProfileContextProvider>
  )
}

export default App
