import { createTheme } from '@mui/material/styles'
import { grey } from '@mui/material/colors'
import LinkBehavior from './components/LinkBehavior'

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 375, // iPhone X
      tablet: 768, // iPad Mini
    },
  },
  palette: {
    background: {
      contrast: grey[100],
    },
  },
  // https://mui.com/material-ui/guides/routing/#LinkRouterWithTheme.js
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
})

export default theme
