import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  onSnapshot,
  runTransaction,
  getFirestore,
  serverTimestamp,
} from 'firebase/firestore'

import useLessonParams from '../../../../hooks/useLessonParams'
import { highlightsRef } from '../../../../configs/firestore'
import * as types from '../../../../types'

const Highlights = createContext({})

const applyHighlights = (tokens, highlights) =>
  tokens.map((t) =>
    highlights.includes(t.id)
      ? {
          ...t,
          hl: true,
        }
      : _.omit(t, 'hl')
  )

const sentenceHighlightReducer = (state, action) => {
  const applyNewSelection = (endId) => {
    if (!state.startNew) return state.highlights
    const hlSlicer = (i) => ![state.startNew, endId].filter(Boolean).includes(i)
    const slice = _(state.tokenIds).dropWhile(hlSlicer).dropRightWhile(hlSlicer)
    return (
      state.add ? slice.union(state.backup) : slice.xor(state.backup)
    ).value()
  }

  switch (action.type) {
    case 'start':
      if (!action.id) return state
      return {
        ...state,
        backup: [...state.highlights],
        startNew: action.id,
        add: !state.highlights.includes(action.id),
      }
    case 'move': {
      if (!action.id || !state.startNew) return state
      const highlights = applyNewSelection(action.id)
      return {
        ...state,
        highlights,
        tokens: applyHighlights(state.tokens, highlights),
      }
    }
    case 'end': {
      const highlights = action.id
        ? applyNewSelection(action.id)
        : state.highlights
      return {
        ...state,
        highlights,
        tokens: applyHighlights(state.tokens, highlights),
        startNew: null,
        backup: [],
        modifiedAt: new Date(),
      }
    }
    default:
      return state
  }
}

const lessonHighlightsReducer = (state, action) => {
  switch (action.type) {
    case 'load': {
      const loadedHighlights = _(action.snapshot.highlights)
        .map(({ id, tokens }) => [
          id,
          tokens.filter((t) => t.hl).map((t) => t.id),
        ])
        .reject(([, tokens]) => _.isEmpty(tokens))
        .fromPairs()
        .value()
      return _.mapValues(state, (s, sid) => {
        if (!loadedHighlights[sid]) return s
        return {
          ...s,
          highlights: loadedHighlights[sid],
          tokens: applyHighlights(s.tokens, loadedHighlights[sid]),
        }
      })
    }
    default: {
      const sentenceState = state[action.sentenceId]
      const newSentenceState = sentenceHighlightReducer(sentenceState, action)
      return {
        ...state,
        [action.sentenceId]: newSentenceState,
        modifiedAt: newSentenceState.modifiedAt,
      }
    }
  }
}

// TODO create lesson context
export const HighlightsContextProvider = ({
  name,
  sentences,
  children,
  disabled,
}) => {
  const { uid, id } = useLessonParams()

  const [lessonHighlights, dispatch] = useReducer(
    lessonHighlightsReducer,
    _(sentences)
      .map((s, index) => [
        s.id,
        { ...s, index, tokenIds: s.tokens.map((t) => t.id), highlights: [] },
      ])
      .fromPairs()
      .value()
  )

  // Load highlights from Firestore
  useEffect(() => {
    if (!uid || !id || disabled) return () => {}
    const unsubscribe = onSnapshot(highlightsRef({ uid, id }), (snapshot) => {
      if (!snapshot.exists()) return
      dispatch({ type: 'load', snapshot: snapshot.data() })
    })
    return () => {
      unsubscribe()
    }
  }, [uid, id, disabled])

  const timeoutIdRef = useRef(null)
  const [savedAt, setSavedAt] = useState(null)

  // Save highlights to Firestore
  useEffect(() => {
    const { modifiedAt } = lessonHighlights
    if (!modifiedAt || modifiedAt <= savedAt || disabled) return
    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current)
    timeoutIdRef.current = setTimeout(() => {
      setSavedAt(modifiedAt)
      const patch = _(lessonHighlights)
        .reject((s) => _.isEmpty(s.highlights))
        .map((s) =>
          _(s)
            .pick([
              'id',
              'index',
              'tokens',
              'original',
              'translation',
              'offset',
              'modifiedAt',
              'lang',
            ])
            .omitBy(_.isNil)
            .value()
        )
        .value()
      runTransaction(getFirestore(), async (tx) => {
        const snap = await tx.get(highlightsRef({ uid, id }))
        if (!snap.exists()) {
          tx.set(snap.ref, {
            name,
            uid,
            lessonId: id,
            highlights: patch,
            createdAt: serverTimestamp(),
            modifiedAt: serverTimestamp(),
          })
        } else {
          tx.update(snap.ref, {
            highlights: patch,
            modifiedAt: serverTimestamp(),
          })
        }
      })
    }, 1000)
  }, [lessonHighlights, savedAt, name, uid, id, disabled])

  const startSelection = useCallback(
    (sentenceId, tokenId) => {
      if (disabled) return
      // Stop saving if a user wants to start a new selection
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current)
      dispatch({ type: 'start', sentenceId, id: tokenId })
    },
    [dispatch, disabled]
  )

  const moveSelection = useCallback(
    (sentenceId, tokenId) => {
      if (disabled) return
      dispatch({ type: 'move', sentenceId, id: tokenId })
    },
    [dispatch, disabled]
  )

  const endSelection = useCallback(
    (sentenceId, tokenId) => {
      if (disabled) return
      dispatch({ type: 'end', sentenceId, id: tokenId })
    },
    [dispatch, disabled]
  )

  const value = useMemo(
    () => ({
      lessonHighlights,
      startSelection,
      moveSelection,
      endSelection,
      enabled: !disabled,
    }),
    [lessonHighlights, startSelection, moveSelection, endSelection, disabled]
  )
  return <Highlights.Provider value={value}>{children}</Highlights.Provider>
}

HighlightsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  sentences: PropTypes.arrayOf(types.Sentence).isRequired,
  disabled: PropTypes.bool,
}

HighlightsContextProvider.defaultProps = {
  disabled: false,
}

export const useHighlightsContext = () => useContext(Highlights)
