import React from 'react'
import { IconButton, CircularProgress } from '@mui/material'
import { grey } from '@mui/material/colors'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

import { usePlayerContext } from '../../../../contexts/player'
import usePlaybackProgress from '../../../../hooks/usePlaybackProgress'

const PlayButton = () => {
  const { isLoaded, isPlaying, togglePlay } = usePlayerContext()
  const { progress } = usePlaybackProgress()
  return (
    <IconButton
      aria-label={!isPlaying ? 'play' : 'pause'}
      onClick={togglePlay}
      disabled={!isLoaded}
    >
      {isPlaying ? <PauseIcon color="primary" /> : <PlayArrowIcon />}
      <CircularProgress
        size={40}
        variant="determinate"
        value={100}
        sx={{ position: 'absolute', color: grey[300] }}
      />
      <CircularProgress
        size={40}
        variant="determinate"
        value={progress}
        sx={{ position: 'absolute' }}
      />
    </IconButton>
  )
}

export default PlayButton
