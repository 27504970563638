/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { sendEvent } from '../../../../utils/analytics'
import * as types from '../../../../types'
import { useHighlightsContext } from '.'
import Token from '../Sentence/Token'

const getTokenId = (e) => {
  // A trick to detect touch events target. Falls back to mouse events for desktop.
  const changedTouch = e.changedTouches && e.changedTouches[0]
  const target = changedTouch
    ? document.elementFromPoint(changedTouch.clientX, changedTouch.clientY)
    : e.target
  return _.get(target && target.closest('ruby'), 'dataset.id')
}

// Prevent rerenders when a context changes, otherwise it's too costly
const Highlighter = React.memo(({ sentenceId, lang, tokens }) => {
  const {
    lessonHighlights,
    startSelection,
    moveSelection,
    endSelection,
    enabled,
  } = useHighlightsContext()

  // Get highlights for this sentence
  const highlights = _.get(lessonHighlights, [sentenceId, 'tokens'], [])
    .filter((t) => t.hl)
    .map((t) => t.id)

  const [isStarted, setIsStarted] = useState(false)

  const onEndSelectionOutside = useCallback(
    (e) => {
      setIsStarted(false)
      endSelection(sentenceId, getTokenId(e))
      window.removeEventListener('pointerup', onEndSelectionOutside)
      sendEvent('highlighter.mark')
    },
    [endSelection, sentenceId]
  )

  const onStartSelection = useCallback(
    (e) => {
      e.preventDefault()
      setIsStarted(true)
      startSelection(sentenceId, getTokenId(e))
      window.addEventListener('pointerup', onEndSelectionOutside)
    },
    [onEndSelectionOutside, startSelection, sentenceId]
  )

  const onMoveSelection = useCallback(
    (e) => {
      if (!isStarted) return
      e.preventDefault()
      moveSelection(sentenceId, getTokenId(e))
    },
    [moveSelection, sentenceId, isStarted]
  )

  const onEndSelection = useCallback(
    (e) => {
      e.preventDefault()
      setIsStarted(false)
      endSelection(sentenceId, getTokenId(e))
      window.removeEventListener('pointerup', onEndSelectionOutside)
    },
    [onEndSelectionOutside, endSelection, sentenceId]
  )

  return (
    <>
      {_.map(tokens, (token) => (
        <Token
          key={token.id}
          token={token}
          lang={lang}
          highlighted={enabled && highlights.includes(token.id)}
          onPointerDown={enabled ? onStartSelection : null}
          onPointerMove={enabled ? onMoveSelection : null}
          onPointerUp={enabled ? onEndSelection : null}
        />
      ))}
    </>
  )
})

Highlighter.propTypes = {
  sentenceId: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  tokens: PropTypes.arrayOf(types.Token).isRequired,
}

export default Highlighter
