import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

export const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
})

// Puppeteer adds this parameter to a lesson URL to retrieve PDF for a download
const params = new URLSearchParams(window.location.search)
const appCheckTokenParam = params.get('appCheckToken')
// App Check token could be also configured with .env for development
const appCheckToken =
  appCheckTokenParam || process.env.REACT_APP_APPCHECK_DEBUG_TOKEN

// Setup App Check debug token if presented either way
if (appCheckToken) {
  // https://firebase.google.com/docs/app-check/web/debug-provider
  window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = appCheckToken
}

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_KEY),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
})
