import React, { useEffect, useState } from 'react'
import { Alert, AlertTitle, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useParams } from 'react-router-dom'
import { onSnapshot } from 'firebase/firestore'
import _ from 'lodash'

import { checkoutSessionRef } from '../../configs/firestore'
import MainLayout, { BackButton } from '../../layouts/MainLayout'

const minutes = (sec) => {
  const min = _.ceil(sec / 60)
  const h = _.floor(min / 60)
  const hmin = min - h * 60

  return (
    <>
      {min} minutes ({h > 0 && `${h}h`}
      {hmin > 0 && ` ${hmin}min`})
    </>
  )
}

const Checkout = () => {
  const { id } = useParams()
  const [product, setProduct] = useState()
  const [purchasedQuota, setPurchasedQuota] = useState()

  useEffect(() => {
    if (!id) return () => {}
    const unsubscribe = onSnapshot(checkoutSessionRef(id), (snapshot) => {
      if (snapshot.exists()) {
        setProduct(snapshot.get('product'))
        setPurchasedQuota(snapshot.get('quota'))
      }
    })
    return () => {
      unsubscribe()
    }
  }, [id])

  return (
    <MainLayout
      title="Purchase completed"
      backButton={<BackButton to="/" icon={<CloseIcon />} />}
    >
      <Alert
        action={
          <Button color="inherit" href="/new">
            Create Lesson
          </Button>
        }
      >
        <AlertTitle>
          You purchase is successfully processed. Thank you!
        </AlertTitle>
        {product === 'audio' && (
          <>
            We added {minutes(purchasedQuota)} to your audio recognition quota
          </>
        )}
      </Alert>
    </MainLayout>
  )
}

export default Checkout
