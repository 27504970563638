import React from 'react'
import { Link, Box, Stack, CircularProgress, Alert } from '@mui/material'
import _ from 'lodash'

import MainLayout from '../../layouts/MainLayout'
import useCollection from '../../hooks/useCollection'
import { LessonWrapper, Highlighter } from '../LessonView'
import { formatDate } from '../../utils/date'

const Highlights = () => {
  const {
    docs: highlights,
    isLoading,
    loadMoreRef,
  } = useCollection({
    name: 'highlights',
    chunkSize: 5,
  })

  if (_.isEmpty(highlights) && !isLoading) {
    return (
      <MainLayout title="Highlights" noWrap>
        <Box sx={{ textAlign: 'center' }}>
          <Alert severity="info" sx={{ mb: 1 }}>
            When you highlight words and phrases from lessons, they appear here.
          </Alert>
        </Box>
      </MainLayout>
    )
  }

  return (
    <MainLayout title="Highlights">
      {_(highlights)
        .reject((doc) => _.isEmpty(doc.highlights))
        .map((doc) => (
          <LessonWrapper className="showTranslation" key={doc.id}>
            <section>
              <h1>
                <Link
                  href={`/lesson/${doc.id}`}
                  color="inherit"
                  sx={{ textDecoration: 'none' }}
                >
                  {doc.name} <time>({formatDate(doc.createdAt.toDate())})</time>
                </Link>
              </h1>
            </section>
            {doc.highlights.map((sentence) => (
              <section key={sentence.id}>
                <p>
                  <Highlighter
                    sentenceId={sentence.id}
                    lang={sentence.lang}
                    tokens={sentence.tokens}
                  />
                  {sentence.translation && <i>{sentence.translation}</i>}
                </p>
              </section>
            ))}
          </LessonWrapper>
        ))
        .value()}
      {isLoading ? (
        <Stack margin={2} direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        !_.isEmpty(highlights) && (
          <Box ref={loadMoreRef} sx={{ height: '1px', width: '100%' }} />
        )
      )}
    </MainLayout>
  )
}

export default Highlights
