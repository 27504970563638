import React, { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Typography,
  Button,
  Alert,
  AlertTitle,
  Paper,
} from '@mui/material'
import { updateDoc, serverTimestamp } from 'firebase/firestore'

import { useProfileContext } from '../../contexts/profile'
import MainLayout from '../../layouts/MainLayout'
import DocumentContent from '../../components/DocumentContent'
import { profileRef } from '../../configs/firestore'
import { errorCapture } from '../../utils/errorCapture'
import { formatDate } from '../../utils/date'

const license = '2023-06-22'

export const isLicenseAccepted = (licenseAcceptedAt) => {
  if (!licenseAcceptedAt) return false
  const acceptedAt = licenseAcceptedAt.toDate()
  // license date in UTC
  const licenseDate = new Date(`${license}T00:00:00Z`)
  // compare dates in UTC
  return acceptedAt > licenseDate
}

const LicenseAgreement = () => {
  const [date, setDate] = useState()
  const [content, setContent] = useState()
  const [changelog, setChangelog] = useState()
  const [isPending, setPending] = useState(true)
  const { uid, licenseAcceptedAt, isLoggedIn } = useProfileContext()

  const mustAcceptTerms = isLoggedIn && !isLicenseAccepted(licenseAcceptedAt)
  const isLicenseUpdate = Boolean(mustAcceptTerms && licenseAcceptedAt)

  useEffect(() => {
    window
      .fetch(`/docs/license-agreement.json`)
      .then((res) => res.json())
      .then((document) => {
        setDate(formatDate(document.date))
        setContent(document.content)
        setChangelog(document.changes)
        setPending(false)
      })
  }, [])

  const onSave = async () => {
    if (isPending) return
    setPending(true)
    try {
      await updateDoc(profileRef(uid), { licenseAcceptedAt: serverTimestamp() })
    } catch (e) {
      errorCapture(e)
      setPending(false)
    }
  }

  return (
    <MainLayout
      title="License Agreement"
      isPublic
      noBackButton={mustAcceptTerms}
    >
      {isLicenseUpdate && (
        <Box mb={4}>
          <Alert severity="warning" sx={{ mb: 2 }}>
            <AlertTitle>We updated the license agreement on {date}.</AlertTitle>
            {changelog && changelog.summary}
          </Alert>
          {changelog && (
            <Paper sx={{ p: 2 }}>
              {changelog.details.map(({ category, changes }) => (
                <>
                  <Typography variant="h6" fontWeight={600}>
                    {category}
                  </Typography>
                  <Typography mt={2} mb={2}>
                    {changes}
                  </Typography>
                </>
              ))}
            </Paper>
          )}
        </Box>
      )}
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography>
          <i>Last Modified: {date}</i>
        </Typography>
        {!mustAcceptTerms && licenseAcceptedAt && (
          <Typography>
            <i>Accepted at: {formatDate(licenseAcceptedAt.toDate())}</i>
          </Typography>
        )}
      </Stack>
      <Typography variant="h4" fontWeight={600} mt={2}>
        License Agreement
      </Typography>
      <DocumentContent content={content} />
      {mustAcceptTerms && (
        <Stack direction="row" justifyContent="right" spacing={2}>
          <Button
            href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
            sx={{ whiteSpace: 'nowrap' }}
          >
            Questions?
          </Button>
          <Button variant="contained" disabled={isPending} onClick={onSave}>
            Agree
          </Button>
        </Stack>
      )}
    </MainLayout>
  )
}

export default LicenseAgreement
