import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'

import MainLayout from '../../layouts/MainLayout'
import DocumentContent from '../../components/DocumentContent'
import { formatDate } from '../../utils/date'

const PrivacyPolicyPage = () => {
  const [date, setDate] = useState()
  const [content, setContent] = useState()

  useEffect(() => {
    window
      .fetch('/docs/privacy-policy.json')
      .then((res) => res.json())
      .then((document) => {
        setDate(formatDate(document.date))
        setContent(document.content)
      })
  }, [])

  return (
    <MainLayout title="Privacy Policy" isPublic>
      <Typography mb={2}>
        <i>Effective from: {date}</i>
      </Typography>
      <Typography variant="h4" fontWeight={600}>
        Privacy Policy
      </Typography>
      <DocumentContent content={content} />
    </MainLayout>
  )
}

export default PrivacyPolicyPage
