import React from 'react'
import { Typography, Box, Alert, AlertTitle } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import Tokens from './Tokens'
import Subscription from './Subscription'
import AudioQuota from './AudioQuota'

import { useProfileContext } from '../../../contexts/profile'

const Summary = () => {
  const { subscription, quota, licenseAcceptedAt } = useProfileContext()
  if (!licenseAcceptedAt) return null
  const hasAudioQuota = quota.audio > 0
  return (
    <Box component="section" mt={2}>
      <Typography variant="h5">Billing summary</Typography>
      {hasAudioQuota && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          <AlertTitle>New billing system.</AlertTitle>
          We introduced a{' '}
          <a href="https://saanaru.com/pricing">new billing system</a> where
          services usage is estimated in tokens. You can{' '}
          <b>convert your remaining audio quota to tokens</b> to continue using
          translation and image-to-text services.
        </Alert>
      )}
      <Grid container spacing={4} mt={2} sx={{ alignItems: 'stretch' }}>
        {hasAudioQuota && (
          <Grid tablet={6}>
            <AudioQuota />
          </Grid>
        )}
        <Grid tablet={6}>
          <Tokens />
        </Grid>
        {subscription.status && (
          <Grid tablet={6}>
            <Subscription />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default Summary
