import React from 'react'
import TollIcon from '@mui/icons-material/Toll'
import ActionButton from './ActionButton'
import { useProfileContext } from '../contexts/profile'

const QuotasButton = () => {
  const {
    subscription: { status },
  } = useProfileContext()
  const hasActiveSubscription = status === 'active'
  return (
    <ActionButton
      href={`/billing?backUrl=${window.location.pathname}`}
      icon={<TollIcon />}
      cta={!hasActiveSubscription}
    >
      {hasActiveSubscription ? `Subscription` : 'Subscribe'}
    </ActionButton>
  )
}

export default QuotasButton
