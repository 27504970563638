import React from 'react'
import { UploadedFile } from '../../../../types'

/**
 * Display an uploaded image.
 *
 * @prop {Object} image Uploaded file
 */
const PreviewImage = ({ image }) => {
  const displayUrl = URL.createObjectURL(image)
  return <img src={displayUrl} alt="Uploaded page" />
}

PreviewImage.propTypes = {
  image: UploadedFile.isRequired,
}

export default PreviewImage
