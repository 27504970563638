import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Unstable_Grid2'

import LessonFromText from './LessonFromText'
import LessonFromImage from './LessonFromImage'
import LessonFromAudio from './LessonFromAudio'

const LessonTypeSelect = ({ onSelect }) => {
  return (
    <Grid container spacing={4}>
      <Grid tablet={4}>
        <LessonFromText onSelect={onSelect} />
      </Grid>
      <Grid tablet={4}>
        <LessonFromAudio onSelect={onSelect} />
      </Grid>
      <Grid tablet={4}>
        <LessonFromImage onSelect={onSelect} />
      </Grid>
    </Grid>
  )
}

LessonTypeSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default LessonTypeSelect
