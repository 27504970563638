import React, { useState, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import {
  getFirestore,
  serverTimestamp,
  writeBatch,
  doc,
} from 'firebase/firestore'

import {
  Stack,
  TextField,
  Button,
  TextareaAutosize,
  Alert,
  AlertTitle,
} from '@mui/material'

import { UploadedFile } from '../../../../types'
import {
  lessonsCollection,
  uploadsCollection,
} from '../../../../configs/firestore'
import { errorCapture } from '../../../../utils/errorCapture'
import { useProfileContext } from '../../../../contexts/profile'
import { normalizeLang } from '../../../../utils/languages'

import {
  LessonFormStack,
  LanguageSelect,
  Spinner,
  AudioQuotaAlert,
} from '../../../../components'
import FilesList from '../FilesList'
import uploadFile from './uploadFile'
import { getAudioDuration } from '../../utils'
import { sendEvent } from '../../../../utils/analytics'
import { claimTrial } from '../../../../configs/api'

function getUploadInitialState(files) {
  return _.chain(files)
    .map((file) => [file.name, { status: null, progress: 0 }])
    .fromPairs()
    .value()
}

function uploadStatusReducer(state, { payload }) {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      ...payload,
    },
  }
}

const LessonForm = ({ type, files, onCancel }) => {
  const navigate = useNavigate()
  const [isPending, togglePending] = useState(false)
  const isAudio = type === 'audio'
  const audioFile = isAudio ? files[0] : null

  // User profile data
  const profile = useProfileContext()
  const { isLoading, uid, targetLanguage, nativeLanguage, trialStartedAt } =
    profile
  const tokensQuota = _.get(profile, 'quota.tokens')
  const audioQuota = _.get(profile, 'quota.audio')
  const hasQuota = tokensQuota > 0 || (isAudio && audioQuota > 0)

  // Claim trial quota
  const [claimedTrial, setClaimedTrial] = useReducer(
    (state, action) => _.max([state, action]),
    0
  )
  const isTrialStarted =
    !isLoading && (trialStartedAt || tokensQuota > 0 || claimedTrial > 0)
  useEffect(() => {
    if (isTrialStarted || isPending) return
    togglePending(true)
    claimTrial()
      .then(({ data: { tokens } }) => {
        setClaimedTrial(tokens)
      })
      .catch(errorCapture)
      .finally(() => togglePending(false))
  }, [isTrialStarted, isPending])

  // Get audio file duration if applicable
  const [audioLength, setAudioLength] = useState()
  useEffect(() => {
    if (audioFile)
      // TODO upload file to server and get duration there
      getAudioDuration(audioFile).then((seconds) => {
        setAudioLength(seconds || 0)
      })
  }, [audioFile])

  // Form value state
  const [name, setName] = useState(
    audioFile && audioFile.name ? audioFile.name.replace(/\.[^.]+$/, '') : ''
  )
  const [content, setContent] = useState('')
  const [selectedLanguage, setLanguage] = useState('')
  const lang = selectedLanguage || targetLanguage || ''
  const languageCode = normalizeLang(lang)
  const isFileTooBig = files.reduce(
    (res, file) => res || file.size > 100 * 1024 * 1024,
    false
  )
  const isReady = Boolean(
    hasQuota &&
      !isFileTooBig &&
      _.size(name) > 0 &&
      lang &&
      languageCode !== nativeLanguage &&
      // Lesson has to have either text content or files ready to upload
      (_.size(content) > 0 || _.size(files))
  )

  // A dictionary where keys are file names and values appear when a file ready for upload
  const [uploadStatus, dispatch] = useReducer(
    uploadStatusReducer,
    files,
    getUploadInitialState
  )
  const setUploadStatus = (file, statusUpdate) => {
    dispatch({ payload: { name: file.name, ...statusUpdate } })
  }

  const onSave = async () => {
    if (!isReady) return
    togglePending(true)

    let lessonId
    let uploadIds = []

    // Save Lesson and Upload documents to Firestore
    try {
      const lessonRef = doc(lessonsCollection(uid))
      lessonId = lessonRef.id
      const batch = writeBatch(getFirestore())
      uploadIds = _.map(files, (file) => {
        const uploadRef = doc(uploadsCollection(uid))
        batch.set(uploadRef, {
          uid,
          lessonId,
          originalName: file.name,
          locale: lang, // save language choice with locale info, e.g. en-US
          createdAt: serverTimestamp(),
          size: file.size,
        })
        return uploadRef.id
      })
      batch.set(
        lessonRef,
        _.omitBy(
          {
            content,
            uid,
            name,
            lang, // save language choice with locale info, e.g. en-US
            type,
            parsingStatus: type === 'audio' ? 'pending' : null,
            uploadIds,
            createdAt: serverTimestamp(),
          },
          _.isEmpty
        )
      )
      // Save data to Firestore to create a lesson before uploading files to storage.
      // This way deleting the lesson will cleanup uploading artifacts (if some of uploads failed)
      await batch.commit()
    } catch (error) {
      errorCapture(error)
      togglePending(false)
      return
    }

    // Upload selected files
    try {
      await Promise.all(
        _.zip(files, uploadIds).map(([file, id]) => {
          return uploadFile(
            {
              file,
              uid,
              id,
              // https://cloud.google.com/storage/docs/metadata#content-language
              contentLanguage: languageCode,
            },
            (payload) => setUploadStatus(file, payload)
          )
        })
      )
    } catch (error) {
      errorCapture(error)
      togglePending(false)
      return
    }

    sendEvent(
      'lesson.create',
      _.omitBy(
        {
          type,
          duration: audioLength,
        },
        _.isEmpty
      )
    )

    navigate(`/lesson/${lessonId}`)
    togglePending(false)
  }

  if (isFileTooBig) {
    return (
      <Alert
        severity="error"
        action={
          <Button color="inherit" onClick={onCancel}>
            Try again
          </Button>
        }
      >
        <AlertTitle>The file you&apos;re uploading is too big</AlertTitle>
        It must be under 100 Mb
      </Alert>
    )
  }

  if (type === 'audio') {
    if (_.isNil(hasQuota)) return null
    if (audioLength && !hasQuota)
      return <AudioQuotaAlert audioLength={audioLength} />
  }

  let contentInput = null
  switch (type) {
    case 'image':
    case 'audio':
      // Show spinner if files are not ready (see `LessonTypeSelect`)
      contentInput = !files.length ? (
        <Spinner />
      ) : (
        <FilesList files={files} type={type} uploadStatus={uploadStatus} />
      )
      break
    default:
      contentInput = (
        <TextareaAutosize
          value={content}
          placeholder="Put lesson text content here"
          onChange={(e) => setContent(e.target.value)}
          minRows={25}
          style={{ fontSize: '16px' }}
          required
        />
      )
  }

  return (
    <LessonFormStack>
      {claimedTrial > 0 && (
        <Alert severity="success">
          <AlertTitle>Free trial activated!</AlertTitle>
          We allocated {claimedTrial} tokens to your account. You can use them
          to create lessons.
        </Alert>
      )}
      <TextField
        label="Lesson Name"
        value={name}
        placeholder="E.g. chapter or episode"
        onChange={(e) => setName(e.target.value)}
        autoFocus
        size="small"
        disabled={isPending}
      />

      <LanguageSelect
        disabled={isPending}
        value={lang}
        onChange={setLanguage}
        variant={type}
        error={
          languageCode === nativeLanguage
            ? "It cannot match your native language. Please update your profile if it's a mistake."
            : null
        }
      />

      {contentInput}

      <Stack direction="row" justifyContent="flex-end">
        <Button
          variant="contained"
          disabled={!isReady || isPending}
          onClick={onSave}
        >
          Save
        </Button>
      </Stack>
    </LessonFormStack>
  )
}

LessonForm.propTypes = {
  type: PropTypes.oneOf(['image', 'audio', 'text']).isRequired,
  files: PropTypes.arrayOf(UploadedFile).isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default LessonForm
