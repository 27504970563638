import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { updateDoc } from 'firebase/firestore'
import {
  Alert,
  Button,
  Stack,
  TextareaAutosize,
  Typography,
  Box,
} from '@mui/material'

import { uploadRef } from '../../../configs/firestore'
import { useDeviceContext } from '../../../contexts/device'
import { errorCapture } from '../../../utils/errorCapture'
import { UploadedFileDoc } from '../../../types'
import { PreloaderNewLesson } from '../../../components/PreloaderNewLesson'
import { DocumentBox } from './styles'

// We need to wrap component in `forwardRef` in order to gain
// access to the ref object that is assigned using the `ref` prop.
// This ref is passed as the second parameter to the function component.
// https://stackoverflow.com/a/37950970
export const UploadEdit = forwardRef(({ doc }, elRef) => {
  const navigate = useNavigate()
  const { isMobile } = useDeviceContext()

  const { id, uid, sourceFile } = doc
  const [imageUrl, setImageUrl] = useState()

  // TODO consider using some React Form library
  const [defaultText, setDefaultText] = useState('')
  const [text, setText] = useState('')
  const textTrimmed = _.trim(text)
  const [defaultPageNumber, setDefaultPageNumber] = useState('')
  const [pageNumber, setPageNumber] = useState('')
  const [showImage, toggleShowImage] = useState(!isMobile)

  useEffect(() => {
    if (doc.status !== 'parsed') return
    const textValue =
      doc.content ||
      (!_.isEmpty(doc.sentences)
        ? doc.sentences.join('\n\n') // BACKWARD compatibility
        : doc.parsedText.join('\n\n'))
    setDefaultText(textValue)
    setText(textValue)
    if (doc.pageNumber) {
      setDefaultPageNumber(doc.pageNumber)
      setPageNumber(doc.pageNumber)
    }
  }, [doc])

  const hasChanges =
    (text && textTrimmed !== defaultText) ||
    (pageNumber && pageNumber !== defaultPageNumber)

  // Get url to display original photo
  useEffect(() => {
    if (!sourceFile) return
    getDownloadURL(ref(getStorage(), sourceFile))
      .then((url) => setImageUrl(url))
      .catch((error) => {
        errorCapture(error)
      })
  }, [sourceFile])

  const onResetButtonClick = () => {
    setText(defaultText)
    setPageNumber(defaultPageNumber)
  }

  const onSaveButtonClick = async () => {
    if (hasChanges) {
      await updateDoc(uploadRef({ uid, id }), {
        pageNumber: Number(pageNumber),
        content: textTrimmed,
      })
      setDefaultPageNumber(pageNumber)
      setDefaultText(textTrimmed)
    }
    return textTrimmed
  }

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  // https://stackoverflow.com/a/37950970
  useImperativeHandle(elRef, () => ({
    save() {
      return onSaveButtonClick()
    },
    isValid() {
      return true
    },
  }))

  const toggleShowOriginalPhoto = () => {
    toggleShowImage(!showImage)
  }

  switch (doc.status) {
    case 'error':
      return (
        <>
          <Typography color="red">
            Image upoad failed
            {doc.errorMessage ? `: ${doc.errorMessage}` : null}
          </Typography>
          <Button variant="contained" onClick={() => navigate('/lesson')}>
            Try upload again
          </Button>
        </>
      )

    case 'noQuota':
      return (
        <DocumentBox>
          <Box>
            <Alert severity="error">
              Not enough quota to process the image
            </Alert>
          </Box>
          <img src={imageUrl} alt="Original page" />
        </DocumentBox>
      )

    case 'parsed':
      return (
        <DocumentBox>
          {isMobile && (
            <Button variant="text" onClick={toggleShowOriginalPhoto}>
              {showImage ? 'Hide original photo' : 'Show original photo'}
            </Button>
          )}

          {isMobile && showImage ? null : (
            <Stack gap={2}>
              <TextareaAutosize
                id="uploaded-doc-text"
                defaultValue={text}
                onChange={(e) => setText(e.target.value)}
                required
                maxRows={28}
              />

              <Button
                color="error"
                variant="outlined"
                onClick={onResetButtonClick}
                disabled={!hasChanges}
              >
                Reset changes
              </Button>
            </Stack>
          )}

          {showImage && imageUrl ? (
            <img src={imageUrl} alt="Original page" />
          ) : null}
        </DocumentBox>
      )
    default:
      return <PreloaderNewLesson />
  }
})

UploadEdit.propTypes = {
  doc: UploadedFileDoc.isRequired,
}
