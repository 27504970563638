import { useState, useEffect, useCallback } from 'react'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { Howl, Howler } from 'howler'

import { errorCapture } from '../utils/errorCapture'
import { sendEvent } from '../utils/analytics'

/**
 * @typedef {Object} PlayerOptions
 *
 * @property {string} file - The audio file path
 * @property {number} duration - The duration of the audio file
 */

/**
 * Custom hook for managing audio player
 * @param {PlayerOptions} options - The player options
 */
function usePlayer({ file, duration }) {
  const [url, setUrl] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [initPlayer, setInitPlayer] = useState(null)
  const [isPlaying, setPlaying] = useState(false)
  const [player, setPlayer] = useState(null)
  const [rate, setRate] = useState(1)

  // Get audio file URL on page load
  useEffect(() => {
    if (file)
      getDownloadURL(ref(getStorage(), file))
        .then((value) => {
          setUrl(value)
        })
        .catch((error) => {
          errorCapture(error)
        })
    else setUrl(null)
  }, [file])

  // Create player instance only after user interacted
  useEffect(() => {
    if (!url || initPlayer === null) return () => {}
    setLoading(true)

    const howl = new Howl({
      src: [url],
      html5: true,

      onplay: () => {
        setPlaying(true)
      },
      onpause: () => {
        setPlaying(false)
      },
      onend: () => {
        setPlaying(false)
      },
    })

    // Start playing if playback is from the beginning, because we don't have
    // to wait for the file to fully load
    if (initPlayer === 0) howl.play()

    // Start playing after player is initialized
    howl.once('load', () => {
      if (!howl.playing()) {
        howl.seek(initPlayer)
        howl.play()
      }
      setLoading(false)
      sendEvent('player.play')
    })

    setPlayer(howl)
    return () => {
      Howler.unload()
    }
  }, [url, initPlayer])

  const togglePlay = useCallback(() => {
    if (isLoading) return
    // Initialize player with autoplay if needed
    if (!player) {
      setInitPlayer(0)
      return
    }
    // Toggle play/pause if player is initialized
    if (!player.playing()) {
      player.play()
    } else {
      player.pause()
    }
  }, [isLoading, player])

  const play = useCallback(
    (time) => {
      if (isLoading) return
      // Initialize player with autoplay if needed
      if (!player) {
        setInitPlayer(time)
        return
      }
      // Play from time if player is initialized
      player.seek(time)
      player.play()
    },
    [isLoading, player]
  )

  const pause = useCallback(() => player?.pause(), [player])
  const toggleRate = useCallback(
    (value) => {
      if (!player) return
      const newRate = value || 1
      player.rate(newRate)
      setRate(newRate)
    },
    [player]
  )

  // Playback controls
  useEffect(() => {
    // Sync Media Session with Howl
    // https://github.com/goldfire/howler.js/issues/1175
    navigator.mediaSession?.setActionHandler('play', () => play())
    navigator.mediaSession?.setActionHandler('pause', () => pause())

    // Toggle play/pause on spacebar
    const toggleOnSpacebar = (e) => {
      if (e.code === 'Space') {
        togglePlay()
        e.preventDefault()
      }
    }
    window.addEventListener('keydown', toggleOnSpacebar)

    return () => {
      navigator.mediaSession?.setActionHandler('play', null)
      navigator.mediaSession?.setActionHandler('pause', null)
      window.removeEventListener('keydown', toggleOnSpacebar)
    }
  }, [player, play, pause, togglePlay])

  return {
    isLoaded: Boolean(url) || !isLoading,
    isPlaying,
    duration,
    play,
    togglePlay,
    rate,
    toggleRate,
    player, // for Howler API access, mostly to use seek()
  }
}

export default usePlayer
