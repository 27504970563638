import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

import usePlayer from '../hooks/usePlayer'

const Player = createContext({})

export const PlayerContextProvider = ({ file, duration, children }) => {
  const data = usePlayer({ file, duration })
  return <Player.Provider value={data}>{children}</Player.Provider>
}

PlayerContextProvider.propTypes = {
  file: PropTypes.string,
  duration: PropTypes.number,
  children: PropTypes.node.isRequired,
}

PlayerContextProvider.defaultProps = {
  file: null,
  duration: null,
}

export const usePlayerContext = () => useContext(Player)
