import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth'
import { Alert, AlertTitle } from '@mui/material'

import { emailCacheKey } from '../../configs/auth'
import AuthLayout from '../../layouts/AuthLayout'
import EmailForm from '../../components/EmailForm'

const SigninConfirm = () => {
  const [status, setStatus] = useState('')
  const navigate = useNavigate()
  const location = useLocation()

  const cachedEmail =
    window.localStorage.getItem(emailCacheKey) ||
    new URLSearchParams(location.search).get('email') ||
    ''
  const [email, setEmail] = useState(cachedEmail)
  const isSignIn = isSignInWithEmailLink(getAuth(), window.location.href)

  useEffect(() => {
    if (!isSignIn) return
    // The client SDK will parse the code from the link for you.
    signInWithEmailLink(getAuth(), email)
      .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem(emailCacheKey)
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        setStatus('success')

        // Clean auth params from URL
        const params = new URLSearchParams(location.search)
        params.delete('apiKey')
        params.delete('oobCode')
        params.delete('mode')
        params.delete('lang')
        params.delete('email')
        let paramsString = params.toString()
        paramsString = paramsString ? `?${paramsString}` : ''
        navigate(`${location.pathname}${paramsString}`, { replace: true })
      })
      .catch((error) => {
        setStatus(error.code)
      })
  }, [isSignIn, email, navigate, location.pathname, location.search])

  const onSubmit = ({ email: value }) => {
    setEmail(value)
    setStatus('')
  }

  // We need a user to see something and interact with this page only if an error happened.
  // Otherwise we show a blank screen since the user will be redirected in a moment.
  switch (status) {
    case 'auth/internal-error':
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again.
      return (
        <AuthLayout title="Login">
          <EmailForm buttonText="Confirm" onSubmit={onSubmit} />
          <Alert severity="warning" sx={{ mb: 4 }}>
            <AlertTitle>Email verification</AlertTitle>
            We don&apos;t recognize the browser as one where you started the
            authentication process. For security reasons, please confirm the
            email address.
          </Alert>
        </AuthLayout>
      )
    case 'auth/invalid-action-code':
    case 'auth/invalid-email':
      return (
        <AuthLayout title="Login">
          <Alert severity="error">
            <AlertTitle>Email verification</AlertTitle>
            We weren&apos;t able to authenticate you. It looks like the email
            link expired or the email does not match. Please try to&nbsp;
            <a href="/login">login again</a>
          </Alert>
        </AuthLayout>
      )
    default:
      return null
  }
}

export default SigninConfirm
