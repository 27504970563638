import React, { useReducer } from 'react'
import { Skeleton, Alert, Button, Typography } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import NotesIcon from '@mui/icons-material/Notes'
import _ from 'lodash'

import useLesson from '../../hooks/useLesson'
import useLessonParams from '../../hooks/useLessonParams'
import { PlayerContextProvider } from '../../contexts/player'

import {
  AudioQuotaAlert,
  AudioRefundAlert,
  TranslationQuotaAlert,
} from '../../components'
import MainLayout, { BackButton } from '../../layouts/MainLayout'

import { formatDate } from '../../utils/date'
import ActionsMenu from './components/ActionsMenu'
import LessonToolbar from './components/LessonToolbar'
import { LessonWrapper, LessonInfo } from './styles'
import LessonEditImage from '../LessonEdit/LessonEditImage'
import { Section } from '../../layouts/MainLayout/styles'
import Content from './components/Content'

const back = <BackButton to="/" />

const preloader = (
  <>
    <Skeleton variant="text" animation="pulse" />
    <Skeleton variant="text" animation="pulse" />
    <Skeleton variant="text" animation="pulse" />
  </>
)

const LessonView = () => {
  const { id, uid } = useLessonParams()
  const lesson = useLesson({ id, uid })
  const { isLoading, translationLanguage, content, type, sentences } = lesson

  const [viewOptions, updateViewOptions] = useReducer(
    (state, options) => ({ ...state, ...options }),
    {}
  )

  const isTranslationOn = _.get(viewOptions, 'translation', true)
  const isSyntaxOn = _.get(viewOptions, 'syntax', false)
  const isAnnotationOn = _.get(viewOptions, 'annotation', false)
  const annotationModes = _.keys(
    _.omitBy(_.get(viewOptions, 'annotationModes'), (v) => !v)
  )

  // Show preloader while reading from Firestore
  if (isLoading) {
    return <MainLayout backButton={back}>{preloader}</MainLayout>
  }

  // Let user clean up scanned text
  if (type === 'image' && !content) {
    return <LessonEditImage />
  }

  switch (lesson.status) {
    case 'noQuota':
      return (
        <MainLayout actions={<ActionsMenu lesson={lesson} />} backButton={back}>
          <AudioQuotaAlert audioLength={lesson.duration} />
        </MainLayout>
      )
    case 'refunded':
      return (
        <MainLayout actions={<ActionsMenu lesson={lesson} />} backButton={back}>
          <AudioRefundAlert audioLength={lesson.duration} />
        </MainLayout>
      )
    default:
      break
  }

  if (_.isEmpty(sentences)) {
    return (
      <MainLayout actions={<ActionsMenu lesson={lesson} />} backButton={back}>
        <Alert
          severity="info"
          sx={{ mb: 1 }}
          action={<Button href="/new">Create one more</Button>}
        >
          Create more lessons while waiting for this one to get parsed.
        </Alert>
        {preloader}
      </MainLayout>
    )
  }

  return (
    <PlayerContextProvider
      file={lesson.isPlayable && lesson.audioFile}
      duration={lesson.duration}
      sentences={sentences}
    >
      <MainLayout
        title={lesson.name}
        noHeaderTitle
        actions={
          <>
            <LessonToolbar
              lesson={lesson}
              translationLang={translationLanguage}
              onChange={updateViewOptions}
            />
            <ActionsMenu lesson={lesson} />
          </>
        }
        backButton={back}
        noWrap
      >
        <LessonInfo>
          Created with Saanaru.com, {formatDate(lesson.createdAt.toDate())}
        </LessonInfo>
        <Section sx={{ alignItems: 'center' }}>
          <aside>
            {lesson.isPlayable ? <AccessTimeIcon /> : <NotesIcon />}
          </aside>
          <Typography variant="h5" component="h1">
            {lesson.name}
          </Typography>
        </Section>
        <LessonWrapper
          className={_([
            'lesson',
            lesson.isPlayable && 'showTimestamps',
            isTranslationOn && 'showTranslation',
            isAnnotationOn && 'showAnnotation',
            isSyntaxOn && 'showSyntax',
            'highlighter',
          ])
            .concat(annotationModes)
            .reject(_.isEmpty)
            .join(' ')}
        >
          {lesson.translationStatus === 'noQuota' && (
            <TranslationQuotaAlert
              characters={sentences.map((s) => s.original).join('').length}
            />
          )}
          <Content name={lesson.name} sentences={sentences} />
        </LessonWrapper>
      </MainLayout>
    </PlayerContextProvider>
  )
}

export default LessonView
