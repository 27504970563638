import React from 'react'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import ActionButton from '../../../components/ActionButton'

const FeedbackButton = () => {
  return (
    <ActionButton
      icon={<ChatBubbleOutlineIcon />}
      href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
    >
      Feedback
    </ActionButton>
  )
}

export default FeedbackButton
