import React from 'react'
import { Alert, AlertTitle, Typography } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import SchoolIcon from '@mui/icons-material/School'
import MainLayout from '../../layouts/MainLayout'
import { Article } from './styles'

const LessonHelp = () => {
  return (
    <MainLayout title="How to learn from a content" isPublic noWrap>
      <Article>
        <Typography variant="h4">Build your comprehension</Typography>
        <Typography variant="body1">
          You goal is to understand at least 80% of the content while listening.
          To achieve it, you should buid up your understanding with a set of
          exersises.
        </Typography>
        <Alert severity="warning" icon={<AccessTimeIcon />}>
          <AlertTitle>30 minutes every day</AlertTitle>
          Choose 5 min audio when starting out to get familiar with the process.
          Switch to 10-15 minutes audios when you are ready.
        </Alert>
        <Typography variant="h5">1. Understand the gist</Typography>
        <Alert severity="info" icon={<SchoolIcon />}>
          Learn how to infer a meaning from a context.
        </Alert>
        <Typography variant="body1">
          You must know what the content is about, passive listening won&apos;t
          help. Listen the audio and read the transcript along to help you
          recognize the words. Check the interlinar translation quickly when
          needed. Keep the following in mind:
        </Typography>
        <Typography variant="body1" component="ul">
          <li>
            You don&apos;t have to understand every word &mdash; 80% is enough.
          </li>
          <li>If a sentence is too complicated &mdash; skip it.</li>
          <li>
            Both a transcript and a translation are not perfect and should be
            considered as a hint.
          </li>
          <li>
            Don&apos;t hesitate to read the translation. Then go over again
            reading the transcript.
          </li>
        </Typography>
        <Typography variant="h5">2. Highlight words and phrases</Typography>
        <Alert severity="info" icon={<SchoolIcon />}>
          Train your brain to pick up on what interests you.
        </Alert>
        <Typography variant="body1">
          Memoizing thousands of words from a dictionary is a blant approach.
          You will learn all the frequent words naturally by reading and
          listening. But it is important to pick up on words that you want to
          remember and use in your speech. This is a foundation for your active
          vocabulary.
        </Typography>
        <Typography variant="body1" component="ul">
          <li>
            Don&apos;t higighlight every new word thats, only the juicy parts.
          </li>
          <li>Pick 10 words and phrases to remember.</li>
          <li>
            You are going to review your highlights on the following days.
          </li>
          <li>
            You can combine this step with others as you bocome more
            experienced.
          </li>
        </Typography>
        <Typography variant="h5">3. Active listening</Typography>
        <Alert severity="info" icon={<SchoolIcon />}>
          Follow the audio content and hear every word.
        </Alert>
        <Typography variant="body1">
          Put the screen aside and listen the audio without relying on the
          transcript. Connect what you hear with the understanding of the
          content that you got. It&apos;s best to combine it with physical
          activity such us walking, making dishes, anything that does not occupy
          your thinking.
        </Typography>
        <Typography variant="body1" component="ul">
          <li>
            Try hearing every word and don&apos;t let your mind drift away.
          </li>
          <li>If you heard something interesting make a highlight.</li>
          <li>
            Re-listen the content on a following day to maintained what
            you&aposl;ve learned.
          </li>
        </Typography>
        <Typography variant="h4">Mindset</Typography>
        <Typography variant="body1" component="ol" mt={2}>
          <li>Goals are personal. Adjust them to your needs as you go.</li>
          <li>Kill your perfectionist. Otherwise it will slow you down.</li>
          <li>
            Combine exersises and make your variations to adopt to your level.
          </li>
        </Typography>
      </Article>
    </MainLayout>
  )
}

export default LessonHelp
