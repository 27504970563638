import { useEffect, useState } from 'react'
import { onSnapshot } from 'firebase/firestore'
import { profileRef } from '../configs/firestore'

export default function useProfile(uid) {
  const [profile, setProfile] = useState({ uid, isLoading: true })

  useEffect(() => {
    if (!uid) return () => {}
    const unsubscribe = onSnapshot(profileRef(uid), (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data()
        const isOnboarded = !!data.nativeLanguage && !!data.targetLanguage
        setProfile({
          ...data,
          uid,
          subscription: data.subscription || {},
          quota: data.quota || {},
          isLoading: false,
          isOnboarded,
          isTrial: !data.subscription?.status,
        })
      }
    })
    return () => {
      unsubscribe()
    }
  }, [uid])

  return profile
}
