import { getStorage, ref, uploadBytesResumable } from 'firebase/storage'

/**
 * Uploads a given file under user's directory.
 *
 * @param {UploadedFileReady} File to upload
 * @param {Function} callback Uploading status update
 * @returns {String} Uploaded file identificator
 * @throws {Error} If upload failed
 */
async function uploadFile({ file, uid, id, contentLanguage }, callback) {
  // Init a page document to get unique ID and link with the image file
  if (callback) callback({ status: 'penging' })

  // Make file name using doc ID and file extension
  let fileExt = ''
  let match = file.name.match(/\.(?<ext>[a-z0-9]+)$/i)
  if (!match) match = file.type.match(/^\w+\/(?<ext>\w+)/i)
  fileExt = match ? match.groups.ext.toLowerCase() : ''
  if (!fileExt) throw new Error(`Cannot get file extension for ${file.name}`)
  const uploadRef = ref(getStorage(), `/${uid}/uploads/${id}.${fileExt}`)

  // Upload file
  const uploadTask = uploadBytesResumable(uploadRef, file, { contentLanguage })
  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_change',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )
        if (callback)
          callback({
            status: 'pending',
            progress,
          })
      },
      (error) => {
        if (callback) callback({ status: 'failed', message: 'Upload failed' })
        reject(error)
      },
      () => {
        if (callback) callback({ status: 'uploaded', progress: 100 })
        resolve(id)
      }
    )
  })
}

export default uploadFile
