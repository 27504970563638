import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

import { useDevice } from '../hooks/useDevice'

const Device = createContext({})

export const DeviceContextProvider = ({ children }) => {
  const data = useDevice()
  return <Device.Provider value={data}>{children}</Device.Provider>
}
DeviceContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useDeviceContext = () => useContext(Device)
