import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Alert, AlertTitle } from '@mui/material'

const AudioRefundAlert = ({ audioLength }) => {
  return (
    <Alert severity="error">
      <AlertTitle>Audio transcription request failed.</AlertTitle>
      We returned {_.ceil(audioLength)} seconds to your quota. You can safely
      delete this lesson.
    </Alert>
  )
}

AudioRefundAlert.propTypes = {
  audioLength: PropTypes.number,
}

AudioRefundAlert.defaultProps = {
  audioLength: null,
}

export default AudioRefundAlert
