import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { UploadedFile } from '../../../../types'
import PreviewImage from './PreviewImage'
import PreviewAudio from './PreviewAudio'
import UploadStatusOverlay, { UploadStatusType } from './UploadStatusOverlay'

const FilesList = ({ files, type, uploadStatus }) => {
  return (
    <ul className="uploaded-file-list">
      {files.map((file) => (
        <li className="uploaded-file" key={file.name}>
          {type === 'audio' ? (
            <PreviewAudio audio={file} key={file.name} />
          ) : (
            <PreviewImage image={file} key={file.name} />
          )}
          <UploadStatusOverlay
            uploadStatus={_.get(uploadStatus, [file.name], {})}
          />
        </li>
      ))}
    </ul>
  )
}

FilesList.propTypes = {
  files: PropTypes.arrayOf(UploadedFile).isRequired,
  type: PropTypes.oneOf(['image', 'audio']).isRequired,
  uploadStatus: PropTypes.objectOf(UploadStatusType).isRequired,
}

export default FilesList
