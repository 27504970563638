import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Stack } from '@mui/material'

const PurchaseItemInfo = ({ name, value, highlight, stripe, color }) => {
  const sx = stripe ? { textDecoration: 'line-through' } : {}
  return (
    <Stack direction="row" spacing={1} justifyContent="space-between">
      <Typography component="p" color={color} sx={sx}>
        {name}
      </Typography>
      <Typography component="p" color={color} sx={sx}>
        {value}
      </Typography>
    </Stack>
  )
}

PurchaseItemInfo.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  color: PropTypes.string,
  highlight: PropTypes.bool,
  stripe: PropTypes.bool,
}

PurchaseItemInfo.defaultProps = {
  highlight: false,
  stripe: false,
  color: '',
}

export default PurchaseItemInfo
