import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import useAuth from '../hooks/useAuth'
import useProfile from '../hooks/useProfile'

const Profile = createContext({})

export const ProfileContextProvider = ({ children }) => {
  const auth = useAuth()
  const profile = useProfile(auth.uid)
  const data = useMemo(
    () => ({
      ...auth,
      ...profile,
      uid: auth.uid,
    }),
    [auth, profile]
  )
  return <Profile.Provider value={data}>{children}</Profile.Provider>
}
ProfileContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useProfileContext = () => useContext(Profile)
