import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Alert, AlertTitle } from '@mui/material'

import { useProfileContext } from '../../contexts/profile'
import BillingPageButton from './BillingPageButton'

const AudioQuotaAlert = ({ audioLength: seconds }) => {
  const { quota } = useProfileContext()
  const audioQuota = quota.audio || 0
  const tokens = quota.tokens || 0

  return (
    <Alert severity="error" action={<BillingPageButton />}>
      <AlertTitle>
        {audioQuota > 0
          ? // For legacy auidio quota
            `You have only ${_.ceil(
              audioQuota / 60
            )} min of audio transcription left.`
          : `You have only ${tokens} tokens of usage quota left.`}
      </AlertTitle>
      The selected file is {_.ceil(seconds / 60)} min long. Please check your
      quota and subscription status on the billing page.
    </Alert>
  )
}

AudioQuotaAlert.propTypes = {
  audioLength: PropTypes.number,
}

AudioQuotaAlert.defaultProps = {
  audioLength: null,
}

export default AudioQuotaAlert
