import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  ListItemIcon,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import PublishIcon from '@mui/icons-material/Publish'
import { useNavigate } from 'react-router-dom'

import { useProfileContext } from '../../../../contexts/profile'
import { errorCapture } from '../../../../utils/errorCapture'
import { shareLesson } from '../../../../configs/api'

const Share = ({ id, onClose }) => {
  const navigate = useNavigate()
  const { creator } = useProfileContext()

  const [isPending, togglePending] = useState(false)

  const [open, setOpen] = useState(false)
  const handleClickOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    onClose && onClose()
  }

  const onConfirm = async () => {
    if (isPending) return
    togglePending(true)
    try {
      await shareLesson({ id })
      navigate('/')
    } catch (error) {
      errorCapture(error)
    }
    togglePending(false)
    handleClose()
  }

  if (!creator) return null

  return (
    <>
      <MenuItem onClick={handleClickOpen} disabled={isPending}>
        <ListItemIcon>
          <PublishIcon color="error" fontSize="small" />
        </ListItemIcon>
        <Typography variant="overline" color="error">
          Share
        </Typography>
      </MenuItem>
      <Dialog open={open} onClose={handleClose} maxWidth="mobile">
        <DialogTitle>Publish this content for everyone?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            It will move this content from under your profile to the public.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Keep it private
          </Button>
          <Button color="error" onClick={onConfirm} autoFocus>
            Share
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

Share.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

Share.defaultProps = {
  onClose: null,
}

export default Share
