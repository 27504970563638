import React, { useEffect, useReducer } from 'react'
import { IconButton, CircularProgress, Typography } from '@mui/material'
import SpeedIcon from '@mui/icons-material/Speed'
import _ from 'lodash'
import { usePlayerContext } from '../../../../contexts/player'

const SpeedButton = () => {
  const { isLoaded, toggleRate } = usePlayerContext()
  const [rates, dispatch] = useReducer(
    (state) => {
      const currentRate = _.first(state)
      return _.union(_.drop(state), [currentRate])
    },
    [1, 0.75, 0.8, 0.85, 0.9, 0.95]
  )
  const rate = _.first(rates)
  const isSlow = rate < 1
  useEffect(() => {
    if (rate) toggleRate(rate)
  }, [rate, toggleRate])

  return (
    <IconButton
      aria-label={isSlow ? 'Normal speed' : 'Slow down'}
      onClick={() => dispatch()}
      disabled={!isLoaded}
    >
      <CircularProgress
        variant="determinate"
        value={rate * 100}
        sx={{ position: 'absolute', color: isSlow ? 'primary' : 'white' }}
      />
      {isSlow && (
        <Typography variant="body2" sx={{ position: 'absolute' }}>
          {String(rate).slice(1).padEnd(3, '0')}x
        </Typography>
      )}
      <SpeedIcon
        color="primary"
        sx={{ visibility: isSlow ? 'hidden' : 'visible' }}
      />
    </IconButton>
  )
}

export default SpeedButton
