import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControlLabel,
  Switch,
  CircularProgress,
  Tooltip,
} from '@mui/material'

const SwitchViewMode = ({ label, checked, loading, onToggle, tooltip }) => {
  return (
    <Tooltip title={tooltip}>
      <FormControlLabel
        disabled={!!loading}
        control={
          loading ? (
            <CircularProgress size={24} sx={{ p: 0.5 }} />
          ) : (
            <Switch
              size="small"
              checked={!!checked}
              onChange={(e) => onToggle(e.target.checked)}
            />
          )
        }
        label={label}
      />
    </Tooltip>
  )
}

SwitchViewMode.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  checked: PropTypes.bool,
  loading: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  tooltip: PropTypes.node,
}

SwitchViewMode.defaultProps = {
  checked: false,
  loading: false,
  tooltip: '',
}

export default SwitchViewMode
